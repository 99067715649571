import { type ColumnsType } from 'antd/es/table';
import type { MorphDictionaryRecord } from './types';
import { partsOfSpeechMap, statusMap } from './types';
import { RecordJoinedFields } from './RecordJoinedFields';
import { TableWithLinks } from '../layout/TableWithLinks';

type DataType = {
  key: React.Key;
} & MorphDictionaryRecord;

const columns: ColumnsType<DataType> = [
  {
    title: 'Лемма',
    dataIndex: 'lemma',
    key: 'lemma',
    render: (lemma: string) => (
      <span style={{ fontFamily: 'MonomakhUnicode' }}>{lemma}</span>
    ),
  },
  {
    title: 'Слово',
    dataIndex: 'word',
    key: 'word',
    render: (word: string) => (
      <span style={{ fontFamily: 'MonomakhUnicode' }}>{word}</span>
    ),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: (status: number) => statusMap.get(status),
  },
  {
    title: 'Часть речи',
    dataIndex: 'partOfSpeech',
    key: 'partOfSpeech',
    render: (partOfSpeech: number) => partsOfSpeechMap.get(partOfSpeech),
    responsive: ['md'],
  },
  {
    title: 'Прочее',
    key: 'description',
    responsive: ['lg'],
    render: (_, record) => (
      <RecordJoinedFields record={record} fontFamily="MonomakhUnicode" />
    ),
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
    responsive: ['md'],
    render: (comment, record) => (
      <span>
        {comment} <b>{record.serviceComment}</b>
      </span>
    )
  },
];

export type MorphDictionaryRecordTableProps = {
  records: MorphDictionaryRecord[];
};

const linkToRecord = (record: DataType) => `/morphology/dictionary/${record.id}`;

export const MorphDictionaryRecordTable = ({
  records,
}: MorphDictionaryRecordTableProps) => {
  const data = records.map((record) => ({
    ...record,
    key: record.id,
  }));
  return (
    <TableWithLinks
      to={linkToRecord}
      pagination={false}
      columns={columns}
      dataSource={data}
    />
  );
};
