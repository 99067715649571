import type { LoaderFunctionArgs } from 'react-router-dom';
import {
  Navigate,
  redirect,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import {
  MorphDictionarySearchForm,
  parseSearchFormParams,
} from './MorphDictionarySearchForm';
import { MorphDictionaryRecordTable } from './MorphDictionaryRecordsTable';
import type { PaginatedArrayOfRecords } from './api';
import * as api from './api';
import { Button, Pagination, message } from 'antd';
import { useState } from 'react';
import { MorphDictionaryRecordView } from './MorphDictionaryRecordView';
import { MorphDictionaryEditForm, type EditFormValues } from './MorphDictionaryEditForm';
import { MorphDictionaryRecord } from './types';
import { Panel } from '../layout/Panel';
import { PageTitle } from '../layout/PageTitle';
import { getAuthToken, buildLoginUrl } from '../profile/utils';
import { ContentTitle } from '../layout/ContentTitle';

const MorphDictionaryCreateRecordButton = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/morphology/dictionary/new');
  };
  return (
    <Button type="primary" htmlType="button" onClick={handleClick}>
      Добавить
    </Button>
  );
};

const titleWithButtonStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const MorphDictionarySearchPage = () => {
  return (
    <PageTitle title="Словарь">
      <Panel style={titleWithButtonStyle}>
        <ContentTitle>Словарь</ContentTitle>
        <MorphDictionaryCreateRecordButton />
      </Panel>
      <Panel>
        <MorphDictionarySearchForm fontFamily="MonomakhUnicode" />
      </Panel>
    </PageTitle>
  );
};

export const loadMorphDictionaryRecords = async ({
  request,
}: LoaderFunctionArgs): Promise<Response | PaginatedArrayOfRecords> => {
  const authToken = getAuthToken();
  if (authToken == null) {
    return redirect(buildLoginUrl(request.url));
  }
  const url = new URL(request.url);
  const urlParams = url.searchParams;
  const params = parseSearchFormParams(urlParams);
  return api.fetchRecords(authToken, params);
};

export const MorphDictionaryTablePage = () => {
  const {
    items: records,
    itemsCount,
    pageSize,
    page,
  } = useLoaderData() as PaginatedArrayOfRecords;
  const navigate = useNavigate();
  const onChangePage = (newPage: number) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('page', newPage.toString());
    urlParams.sort();
    navigate(`${window.location.pathname}?${urlParams.toString()}`);
  };
  return (
    <PageTitle title="Словарь">
      <Panel style={titleWithButtonStyle}>
        <div>
          <ContentTitle>Словарь</ContentTitle>
          <br />
          {itemsCount !== 0 && `Найдено записей ${itemsCount}`}
          {itemsCount === 0 && 'Ничего не найдено'}
        </div>
        <MorphDictionaryCreateRecordButton />
      </Panel>
      <Panel>
        <MorphDictionaryRecordTable records={records} />
        <Pagination
          hideOnSinglePage
          showSizeChanger={false}
          current={page}
          pageSize={pageSize}
          total={itemsCount}
          onChange={onChangePage}
        />
      </Panel>
    </PageTitle>
  );
};

export const loadMorphDictionaryRecord = async ({
  params, request,
}: LoaderFunctionArgs): Promise<Response | MorphDictionaryRecord> => {
  const authToken = getAuthToken();
  if (authToken == null) {
    return redirect(buildLoginUrl(request.url));
  }
  return await api.fetchRecordDetails(authToken, params.recordId!);
};

export const MorphDictionaryRecordPage = () => {
  const record = useLoaderData() as MorphDictionaryRecord;
  const [messageApi, contextHolder] = message.useMessage();
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const switchToEditMode = () => {
    setMode('edit');
  };
  const switchToViewMode = () => {
    setMode('view');
  };
  const navigate = useNavigate();
  const authToken = getAuthToken();
  if (authToken == null) {
    return <Navigate to={buildLoginUrl(window.location.href)} />;
  }
  const handleSaveRecord = async (value: EditFormValues) => {
    try {
      await api.updateRecord(authToken, { ...value, id: record.id });
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: 'Ошибка при отправке данных на сервер',
      });
      return;
    }
    navigate('.', { replace: true });
    setMode('view');
  };
  let content: React.ReactNode;
  let contentTitle: React.ReactNode;
  if (mode === 'view') {
    contentTitle = (
      <ContentTitle style={{ fontFamily: 'MonomakhUnicode' }}>
        {record.word} ({record.lemma})
      </ContentTitle>
    );
    content = (
      <>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <Button type="primary" htmlType="button" onClick={switchToEditMode}>
            Редактировать
          </Button>
        </div>
        <MorphDictionaryRecordView
          fontFamily="MonomakhUnicode"
          record={record}
        />
      </>
    );
  } else {
    contentTitle = <ContentTitle>Редактирование</ContentTitle>;
    content = (
      <>
        <MorphDictionaryEditForm
          fontFamily="MonomakhUnicode"
          initialValue={record}
          onCancel={switchToViewMode}
          onSave={handleSaveRecord}
        />
      </>
    );
  }
  return (
    <PageTitle title={record.word}>
      {contextHolder}
      <Panel>
        <ContentTitle>{contentTitle}</ContentTitle>
      </Panel>
      <Panel>{content}</Panel>
    </PageTitle>
  );
};

export const MorphDictionaryCreateRecordPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const authToken = getAuthToken();
  if (authToken == null) {
    return <Navigate to={buildLoginUrl(window.location.href)} />;
  }
  const handleCancel = () => {
    navigate('/morphology/dictionary');
  };
  const handleSaveRecord = async (value: Omit<MorphDictionaryRecord, 'id'>) => {
    let recordId: string | number;
    try {
      recordId = await api.createRecord(authToken, value);
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: 'Ошибка при отправке данных на сервер',
      });
      return;
    }
    navigate(`/morphology/dictionary/${recordId}`);
  };
  return (
    <PageTitle title="Словарь">
      {contextHolder}
      <Panel>
        <ContentTitle>Добавить запись</ContentTitle>
      </Panel>
      <Panel>
        <MorphDictionaryEditForm
          fontFamily="MonomakhUnicode"
          onCancel={handleCancel}
          onSave={handleSaveRecord}
        />
      </Panel>
    </PageTitle>
  );
};
