import { SaveFilled } from '@ant-design/icons';
import {SlavicTextArea} from "../../morph-dictionary/SlavicField";
import { PageContext } from '../types';
import { Button, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { getAuthToken } from '../../profile/utils';
import * as api from '../api';


export const TextTab = ({
  subTab,
}: {
  subTab: React.ReactNode,
}) => {
  const {page} = useContext(PageContext)!;
  const [messageApi, contextHolder] = message.useMessage();
  const [textLoading, setTextLoading] = useState(false);
  const [text, setText] = useState<string>('');
  useEffect(() => {
    setTextLoading(true);
    api.fetchPageText(authToken, page.id)
      .then((receivedText) => {
        setText(receivedText);
      }).finally(() => {
        setTextLoading(false);
      });
  }, [page]);
  const save = async () => {
    setTextLoading(true);
    try {
      await api.uploadPageText(authToken, page.id, text);
      const receivedText = await api.fetchPageText(authToken, page.id)
      setText(receivedText);
    } catch (error) {
      messageApi.error("Что-то пошло не так")
    }
    setTextLoading(false);
  };
  const handleChange = (value: string) => {
    setText(value);
  };
  const authToken = getAuthToken()!;
  return (
    <>
      {contextHolder}
      <div style={{display: 'flex', alignItems: 'start', flexWrap: 'wrap', rowGap: 15}}>
        <div style={{flex: '1 0 400px'}}>
          {subTab}
        </div>
        <div style={{flex: '1 0 400px'}}>
          <Button
            style={{marginLeft: 20, display: 'block',}}
            type="primary"
            disabled={textLoading}
            onClick={save}
            title="Сохранить текст"
          >
            <SaveFilled/>
          </Button>
          <SlavicTextArea
            style={{fontFamily: 'MonomakhUnicode', whiteSpace: 'nowrap'}}
            autoSize={{minRows: 20}}
            value={text}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  )
};
