import { SaxesParser } from "saxes";

/**
 * Формирование дерева или деревьев React HTML элементов из строки.
 * Создаются не все HTML элементы из строки,
 * а только элементы из небольшого набора, остальные пропускаются.
 */
export const Str2Tag = ({value}: {value: string}) => {
  const topChildren: React.ReactNode[] = [];
  const stack: React.ReactNode[][] = [topChildren];
  let currentText: string = '';

  const parser = new SaxesParser();
  parser.on("text", (text) => {
    currentText += text;
  });
  parser.on("opentag", () => {
    const parentChildren = stack.at(-1)!;
    if (currentText) {
      parentChildren.push(<>{currentText}</>);
      currentText = '';
    }
    // когда начинается новый тег, добавляется массив,
    // в который будут складываться дочерние узлы
    stack.push([]);
  });
  parser.on("closetag", (tag) => {
    const children = stack.pop()!;
    if (currentText) {
      children.push(<>{currentText}</>);
      currentText = '';
    }
    const parentChildren = stack.at(-1)!;
    switch (tag.name.toLowerCase()) {
      case 'em':
        // добавляет <em> элемент. Выделяется жирным для наглядности
        parentChildren.push(
          <em style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}>
            {children}
          </em>
        );
        break;
      case 'br':
        parentChildren.push(
          <br />
        );
        break;
      case 'sup':
        // добавляется <sup> элемент. Это для выносных букв и буквенных титлов
        parentChildren.push(<sup>{children}</sup>);
        break;
      default:
        if (
          'class' in tag.attributes &&
          tag.attributes['class'].includes('ocr_line')
        ) {
          // добавляется <br /> элемент, чтобы показать перевод строки
          children.push(<br />);
        }
        // тег пропускается, дочерние узлы становятся дочерними родителя
        parentChildren.push(...children);
    }
  });
  parser.write('<root>' + value.replace(/\n/g, '<br />') + '</root>').close();
  return <>{topChildren}</>;
};
