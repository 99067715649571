import { API_ROOT } from '../constants';
import { ApiError } from '../layout/errors';
import { serializeSearchParams } from '../utils';
import {
  type SearchParams,
} from './MorphDictionarySearchForm';
import type { MorphDictionaryRecord } from './types';

export type PaginatedArrayOfRecords = {
  items: MorphDictionaryRecord[];
  pageSize: number;
  itemsCount: number;
  page: number;
};

export const fetchRecords = async (
  authToken: string,
  params: SearchParams
): Promise<PaginatedArrayOfRecords> => {
  params.page = params.page ?? 1;
  const page = params.page;
  const paramsString = serializeSearchParams(params);
  const url = `${API_ROOT}/morph-dictionary-records?${paramsString}`;
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (!response.ok) {
    throw new ApiError('records fetching failed', response);
  }
  let { items, itemsCount, pageSize } = await response.json();
  return {
    items,
    pageSize,
    itemsCount,
    page,
  };
};

export const fetchRecordDetails = async (
  authToken: string,
  recordId: string
): Promise<MorphDictionaryRecord> => {
  const url = `${API_ROOT}/morph-dictionary-records/${recordId}`;
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (!response.ok) {
    throw new ApiError('record fetching failed', response);
  }
  return await response.json();
};

type ExtendedRecord = {
  multipleUpdateStatus?: boolean;
  multipleUpdateComment?: boolean;
  multipleUpdateCompleteness?: boolean;
} & MorphDictionaryRecord;

export const updateRecord = async (
  authToken: string,
  record: ExtendedRecord,
) => {
  const url = `${API_ROOT}/morph-dictionary-records/${record.id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(record),
  });
  if (!response.ok) {
    throw new ApiError('record updating failed', response);
  }
};

export const createRecord = async (
  authToken: string,
  record: Omit<ExtendedRecord, 'id'>
): Promise<string> => {
  const url = `${API_ROOT}/morph-dictionary-records`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(record),
  });
  if (!response.ok) {
    throw new ApiError('record updating failed', response);
  }
  const resultRecord = await response.json();
  return resultRecord.id;
};
