import { Button, Spin } from "antd";
import { useContext } from "react";
import { CompareImageView } from "../ImagesView";
import { PageContext } from "../types";
import * as api from "../api";

export const VizualizationImageTab = ({
  field,
}: {
  field: 'binary'|'background'|'segments'|'classified';
}) => {
  const {page, loading, processing, process} = useContext(PageContext)!;
  // @ts-ignore: TypeScript не понимает такую запись
  const {has_visualizations = false} = page;
  // @ts-ignore: TypeScript не понимает такую запись
  const vizualizationImage: api.S3Image = page[field];
  if (!has_visualizations) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{textAlign: 'center'}}>
        <div>Это изображение пока не обрабатывалось</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={process}
            style={{ marginTop: '5px' }}
            disabled={processing}
          >
            {loading && <Spin />}
            {processing && 'Обрабатывается...'}
            {!processing && 'Обработать'}
          </Button>
        </div>
        </div>
      </div>
    );
  }
  const footer = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        onClick={process}
        style={{ marginTop: "5px" }}
        disabled={processing}
      >
        {processing && "Обрабатывается..."}
        {!processing && vizualizationImage && "Обработать повторно"}
        {!processing && !vizualizationImage && "Обработать"}
      </Button>
    </div>
  );
  if (!vizualizationImage) {
    return (
      <div>
        <div>
          Изображение было обработано, но данной визуализации не было создано
        </div>
        {footer}
      </div>
    );
  }
  return (
    <CompareImageView
      topSrc={api.getPageUrl(page)}
      bottomSrc={api.getVisualizationUrl(vizualizationImage)}
    />
  );
};
