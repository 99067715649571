import { Button } from 'antd';
import { DragOutlined, ExpandOutlined, ZoomInOutlined } from '@ant-design/icons';
import { useContext, useEffect, useRef, useState } from "react";
import { ZoomedImage, useImageView } from "../../layout/ZoomedImage";
import { PageContext } from '../types';
import * as api from '../api';

export const OriginalTab = () => {
  const {page} = useContext(PageContext)!;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [view, dispatchView] = useImageView();
  const [mode, setMode] = useState<'zoom'|'translate'|null>(null);
  const [region, setRegion] = useState<{fixed: {x: number, y: number}, moving: {x: number, y:number}} | null>(null);
  const [startPoint, setStartPoint] = useState<{x: number, y: number} | null>(null);
  useEffect(() => {
    dispatchView({type: "reset"});
    setRegion(null);
    setStartPoint(null);
  }, [page]);
  const handleMouseDown = (event: React.MouseEvent) => {
    if (wrapperRef.current == null) {
      return;
    }
    if (mode === 'zoom') {
      if (event.button != 0) return;
      const rect = wrapperRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setRegion({fixed: {x, y}, moving: {x, y}});
    } else if (mode == 'translate') {
      if (event.button != 0) return;
      const rect = wrapperRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setStartPoint({x, y});
    }
  };
  const handleMouseMove = (event: React.MouseEvent) => {
    if (wrapperRef.current == null) {
      return;
    }
    if (mode === 'zoom') {
      if (!(event.buttons & 1)) {
        setRegion(null);
        return;
      };
      const rect = wrapperRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setRegion((area) => area == null ? null : ({fixed: area.fixed, moving: {x, y}}));
    } else if (mode == 'translate' && startPoint != null) {
      if (!(event.buttons & 1)) {
        setStartPoint(null);
        return;
      };
      const rect = wrapperRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      dispatchView({type: "translate", fromPoint: startPoint, toPoint: {x, y}, baseSize: rect});
      setStartPoint({x, y});
    }
  };
  const handleMouseUp = (event: React.MouseEvent) => {
    if (wrapperRef.current == null) {
      return;
    }
    if (mode === 'zoom' && region != null) {
      if (event.button != 0) {
        return;
      };
      const rect = wrapperRef.current.getBoundingClientRect();
      const {fixed, moving} = region;
      dispatchView({
        type: 'region',
        region: {
          left: Math.min(fixed.x, moving.x),
          top: Math.min(fixed.y, moving.y),
          width: Math.abs(fixed.x - moving.x),
          height: Math.abs(fixed.y - moving.y),
        },
        baseSize: rect,
      });
      setRegion(null);
    } else if (mode === 'translate') {
      if (event.button != 0) {
        return;
      };
      setStartPoint(null);
    }
  };
  return (
    <div style={{flex: 1}}>
      <Button
        style={{background: mode === 'zoom' ? '#ccc': '#fff'}}
        onClick={() => {
          setMode(mode => mode === 'zoom' ? null : 'zoom');
          setRegion(null);
        }}
        title="Выделите зону интереса, чтобы приблизить"
      >
        <ZoomInOutlined />
      </Button>
      <Button
        style={{background: mode === 'translate' ? '#ccc': '#fff'}}
        onClick={() => {
          setMode(mode => mode === 'translate' ? null : 'translate');
          setRegion(null);
          setStartPoint(null);
        }}
        title="Переместите изображение левой кнопкой мыши"
      >
        <DragOutlined />
      </Button>
      <Button
        onClick={() => {
          dispatchView({type: "reset"});
          setRegion(null);
          setStartPoint(null);
        }}
        title="Перейти к исходному размеру"
      >
        <ExpandOutlined />
      </Button>
      <div
        ref={wrapperRef}
        style={{position: 'relative', userSelect: 'none'}}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <ZoomedImage
          view={view}
          src={api.getPageUrl(page)}
        />
        {region != null && (
          <div
            style={{
              border: '1px dashed #000',
              position: 'absolute',
              top: Math.min(region.fixed.y, region.moving.y),
              left: Math.min(region.fixed.x, region.moving.x),
              width: Math.abs(region.fixed.x - region.moving.x),
              height: Math.abs(region.fixed.y - region.moving.y),
            }}
          />
        )}
      </div>
    </div>
  );
};
