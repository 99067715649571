import { useState } from "react";

export const useLocalStorage = (
  key: string,
  defaultValue: string | null = null
): [string | null, (value: string | null) => void] => {
  const [state, setState] = useState(localStorage.getItem(key) || defaultValue);
  const setStateWrapper = (value: string | null) => {
    if (value == null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
    setState(value);
  };
  return [state, setStateWrapper];
};

export function serializeSearchParams<Params extends {[key: string]: number | string | null}>(params: Params): string {
  const urlParams = new URLSearchParams();
  for (let key in params) {
    const value = params[key];
    if (value == null) {
      continue;
    }
    urlParams.set(key, value.toString());
  }
  urlParams.sort();
  return urlParams.toString();
};
