import React from "react";
import { type VyazBase } from "../vyaz/types";
import { type S3Image } from "./api";

export const centuryPartOptions = [
  { value: 0, label: 'Весь' },
  { value: 1, label: 'Начало' },
  { value: 2, label: 'Середина' },
  { value: 3, label: 'Конец' },
  { value: 4, label: 'Первая половина' },
  { value: 5, label: 'Вторая половина' },
  { value: 6, label: 'Первая треть' },
  { value: 7, label: 'Вторая треть' },
  { value: 8, label: 'Последняя треть' },
  { value: 9, label: 'Первая четверть' },
  { value: 10, label: 'Вторая четверть' },
  { value: 11, label: 'Третья четверть' },
  { value: 12, label: 'Последняя четверть' },
];

export const WHOLE_CENTURY_RANGE = 0;

export const centuryPartLabelMap = new Map<number, string>();
for (const { value, label } of centuryPartOptions) {
  centuryPartLabelMap.set(value, label);
}

export const musicalSignOptions = [
  { value: 0, label: 'Не указано' },
  { value: 1, label: 'Нет' },
  { value: 2, label: 'Частично нотированная' },
  { value: 3, label: 'Нотированная' },
];

export const musicalSignMap = new Map<number, string>();
for (const { value, label } of musicalSignOptions) {
  musicalSignMap.set(value, label);
}

export const formatOptions = [
  { value: 0, label: 'Не указан' },
  { value: 1, label: '1º' },
  { value: 2, label: '4º' },
  { value: 3, label: '8º' },
];

export const formatMap = new Map<number, string>();
for (const { value, label } of formatOptions) {
  formatMap.set(value, label);
}

export const materialOptions = [
  { value: 0, label: 'Не указан' },
  { value: 1, label: 'Пергамен' },
  { value: 2, label: 'Бумага' },
];

export const materialMap = new Map<number, string>();
for (const { value, label } of materialOptions) {
  materialMap.set(value, label);
}

export const handwritingTypeOptions = [
  { value: 0, label: 'Не указан' },
  { value: 1, label: 'Устав' },
  { value: 2, label: 'Полуустав' },
];

export const handwritingTypeMap = new Map<number, string>();
for (const { value, label } of handwritingTypeOptions) {
  handwritingTypeMap.set(value, label);
}

export const monthOptions = [
  { value: 0, label: 'Январь' },
  { value: 1, label: 'Февраль' },
  { value: 2, label: 'Март' },
  { value: 3, label: 'Апрель' },
  { value: 4, label: 'Май' },
  { value: 5, label: 'Июнь' },
  { value: 6, label: 'Июль' },
  { value: 7, label: 'Август' },
  { value: 8, label: 'Сентябрь' },
  { value: 9, label: 'Октябрь' },
  { value: 10, label: 'Ноябрь' },
  { value: 11, label: 'Декабрь' },
  { value: 12, label: 'Праздничная' },
  { value: 13, label: 'Общая' },
];

export enum SplittedPagesStatus {
  CUT_NOT_EXIST = 0,
  CUT_EXIST = 1,
  PROCESSING = 2,
  DONE = 3,
}

export const monthMap = new Map<number, string>();
for (const { value, label } of monthOptions) {
  monthMap.set(value, label);
}

type DateRangeUnknown = {
  exactDateYear: null;
  firstCentury: null;
  firstCenturyPrefix: null;
  lastCentury: null;
  lastCenturyPrefix: null;
};

type DateRangeYear = {
  exactDateYear: number;
  firstCentury: null;
  firstCenturyPrefix: null;
  lastCentury: null;
  lastCenturyPrefix: null;
};

type DateRangeCentury = {
  exactDateYear: null;
  firstCentury: number;
  firstCenturyPrefix: number;
  lastCentury: null;
  lastCenturyPrefix: null;
};

type DateRangeCenturyRange = {
  exactDateYear: null;
  firstCentury: number;
  firstCenturyPrefix: number;
  lastCentury: number;
  lastCenturyPrefix: number;
};

export type DateRange =
  | DateRangeUnknown
  | DateRangeYear
  | DateRangeCentury
  | DateRangeCenturyRange;

export type Image = {
  id: string;
  filename: string;
  imageNumber: number;
  cutStartX: number | null;
};

export type Page = {
  id: string;
  filename: string;
  pageNumber: number;
  processingPipelineStartedAt: string | null;
};

export type ImageProcResult = {
  binary?: S3Image;
  background?: S3Image;
  segments?: S3Image;
  classified?: S3Image;
  has_visualizations: boolean;
} & Page;

export type WithPagesWithVyaz<T extends ManuscriptBase> = T & {
  pages: (Page & {vyaz: VyazBase})[];
};

export type WithPages<T extends ManuscriptBase> = T & {
  pages: Page[];
};

export type WithImages<T extends ManuscriptBase> = T & { images: Image[] };

export const ImageContext = React.createContext<{image: Image, manuscriptId: string, splittedPagesStatus: number} | null>(null);

type PageContextValue = {
  page: Page | ImageProcResult;
  vyaz: VyazBase | null;
  manuscriptId: string;
  processing: boolean, loading: boolean;
  process: () => void;
};

export const PageContext = React.createContext<PageContextValue | null>(null);

export const ManuscriptContext = React.createContext<{manuscript: Manuscript, switchToEditMode: () => void}| null>(null);

export type Redaction = {
  id: number;
  name: string;
};

export type RepositoryBase = {
  id: number;
  name: string;
  shortName: string;
};

export type Collection = {
  id: number;
  name: string;
  repositoryId: number;
  cipherPrefix: string;
};

export type Repository = RepositoryBase & {
  collections: Collection[];
};

export class RelationNotFoundError extends Error {}

export type ManuscriptBase = {
  id: string;
  cipher: string;
  link?: string;
  note: string;
  specialName: string;
  handwritingType: number;
  numberOfHandwritings: number | null;
  handwritingRegionsDescription: string;
  pagesCount: number | null;
  pagesStateDescription: string;
  redactionId: number;
  format: number;
  material: number;
  thumbnail: string;
  createdAt: Date;
  months: number[];
  musicalSign: number;
  collectionId: number;
  splittedPagesStatus: number;
} & DateRange;

export const enrichManuscript = <T extends ManuscriptBase>(
  base: T,
  repositories: Repository[],
  redactions: Redaction[]
): T & Pick<Manuscript, 'collection'|'repository'|'redaction'> => {
  let repository: null | Repository = null;
  let collection: null | Collection = null;
  for (const currentRepository of repositories) {
    for (const currentCollection of currentRepository.collections) {
      if (currentCollection.id === base.collectionId) {
        repository = currentRepository;
        collection = currentCollection;
        break;
      }
    }
  }
  const redaction = redactions.find(({ id }) => id === base.redactionId);
  if (redaction == null || repository == null) {
    throw new RelationNotFoundError();
  }
  return {
    ...base,
    repository,
    collection: collection!,
    redaction,
  };
};

export type Manuscript = ManuscriptBase & {
  repository: Repository;
  collection: Collection;
  redaction: Redaction;
};
