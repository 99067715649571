import { Button } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { getAuthToken } from "../../profile/utils";
import { ImageContext } from "../types";
import * as api from '../api';

/** Компонент позволяет отрисовывать линию,
 * по которым в дальнейшем выполняется разрезка страницы */
export const SplitImageTab = () => {
  const {image, splittedPagesStatus} = useContext(ImageContext)!;
  const imgRef = useRef<HTMLImageElement>(null);
  const [isHoldingCutX, setIsHoldingCutX] = useState<boolean>(false);
  const [xCut, setXCut] = useState<number | null>(null);
  useEffect(function setup() {
    if (image.cutStartX && imgRef.current && imgRef.current.naturalWidth) {
      setXCut(100 * image.cutStartX / imgRef.current.naturalWidth);
    } else {
      setXCut(null);
    }
  }, [image]);
  const handleLoad = () => {
    if (image.cutStartX && imgRef.current && imgRef.current.naturalWidth) {
      setXCut(100 * image.cutStartX / imgRef.current.naturalWidth);
    } else {
      setXCut(null);
    }
  };
  const setXCutAtMiddle = () => {
    if (imgRef.current == null) {
      return;
    }
    const imageEl = imgRef.current;
    if (imageEl == null) {
      return;
    }
    setXCut(50);
    image.cutStartX = imageEl.naturalWidth / 2;
    api.sendImageXCut(getAuthToken()!, image, Math.round(imageEl.naturalWidth / 2));
  };
  const handleMouseDown = () => {
    setIsHoldingCutX(true);
  };
  const handleMouseMove = (event: React.MouseEvent) => {
    if (imgRef.current != null && isHoldingCutX) {
      const rect = imgRef.current.getBoundingClientRect();
      const newXCut = 100 * (event.clientX - rect.left) / imgRef.current.width;
      setXCut(newXCut);
    }
  };
  const handleMouseUp = () => {
    if (isHoldingCutX && xCut != null && imgRef.current != null) {
      setIsHoldingCutX(false);
      image.cutStartX = xCut / 100 * imgRef.current.naturalWidth;
      api.sendImageXCut(getAuthToken()!, image, Math.round(image.cutStartX));
    }
  };

  const deleteXCut = () => {
    setXCut(null);
    api.sendImageXCut(getAuthToken()!, image, null);
    image.cutStartX = null;
  };
  return (
    <div>
      <div
        style={{position: 'relative'}}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <img
          ref={imgRef}
          style={{width: '100%', display: 'block'}}
          src={api.getImageUrl(image)}
          onLoad={handleLoad}
        />
        {xCut != null && imgRef.current != null && (
          <>
            <div
              style={{
                position: 'absolute', top: 0, bottom: 0, left: `${xCut}%`, transform: 'translateX(-50%)',
                width: '0.5%', background: '#43ff64d9',
              }}
            />
            <div
              style={{
                position: 'absolute', top: 0, bottom: 0, left: `${xCut}%`, transform: 'translateX(-50%)',
                width: '5%', opacity: 0,
                cursor: 'pointer',
              }}
              onMouseDown={handleMouseDown}
            />
          </>
        )}
      </div>
      {splittedPagesStatus < 2 && xCut == null && <Button onClick={setXCutAtMiddle}>Создать линию разреза</Button>}
      {splittedPagesStatus < 2 && xCut != null && <Button onClick={deleteXCut}>Удалить линию разреза</Button>}
    </div>
  );
};
