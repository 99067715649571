import { Button, Descriptions } from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';
import { useNavigate } from 'react-router-dom';
import type { MorphDictionaryRecord } from './types';
import {
  completenessConcisenessCategoryMap,
  degreeOfComparisonMap,
  grammaticalCaseMap,
  grammaticalGenderMap,
  grammaticalMoodMap,
  grammaticalNumberMap,
  grammaticalPersonMap,
  numeralTypeMap,
  participleTypeMap,
  partsOfSpeechMap,
  personalityCategoryMap,
  pronounTypeMap,
  statusMap,
  tenseMap,
  verbRepresentationMap,
  voiceMap,
} from './types';

type MorphDictionaryRecordViewProps = {
  fontFamily: string,
  record: MorphDictionaryRecord;
};

export const MorphDictionaryRecordView = ({
  fontFamily,
  record,
}: MorphDictionaryRecordViewProps) => {
  const { partOfSpeech, numeralType, verbRepresentation, pronounType, grammaticalMood, personalityCategory } = record;
  const hiddenFields: (keyof MorphDictionaryRecord)[] = [];
  if (partOfSpeech !== 1) {
    hiddenFields.push('verbRepresentation', 'presentTenseForm');
  }
  if (verbRepresentation !== 4) {
    hiddenFields.push('participleType', 'voice');
  }
  if (verbRepresentation !== 3) {
    hiddenFields.push('grammaticalMood');
  }
  if (grammaticalMood !== 1) {
    hiddenFields.push('tense');
  }
  if (!(verbRepresentation === 4 || [5, 7, 8, 12].includes(partOfSpeech))) {
    hiddenFields.push('grammaticalCase');
  }
  if (!(numeralType === 3 || verbRepresentation === 4 || pronounType === 2 || [7, 8].includes(partOfSpeech))) {
    hiddenFields.push('grammaticalGender');
  }
  if (!(numeralType === 3 || [3, 4].includes(verbRepresentation) ||[7, 8].includes(partOfSpeech) || [1, 2].includes(pronounType))) {
    hiddenFields.push('grammaticalNumber');
  }
  if (!(verbRepresentation === 3 || personalityCategory === 1)) {
    hiddenFields.push('grammaticalPerson');
  }
  if (![7, 10].includes(partOfSpeech)) {
    hiddenFields.push('degreeOfComparison');
  }
  if (partOfSpeech !== 5) {
    hiddenFields.push('pronounType');
  }
  if (partOfSpeech !== 12) {
    hiddenFields.push('numeralType');
  }
  if (pronounType !== 1) {
    hiddenFields.push('personalityCategory');
  }
  if (!(verbRepresentation === 4 || partOfSpeech === 7)) {
    hiddenFields.push('completenessConcisenessCategory');
  }
  const recordFields = [
    {
      key: 'partOfSpeech',
      label: 'Часть речи',
      children: partsOfSpeechMap.get(record.partOfSpeech),
    },
    {
      key: 'presentTenseForm',
      label: 'Форма настоящего времени',
      children: <span style={{fontFamily}}>{record.presentTenseForm}</span>,
    },
    {
      key: 'verbRepresentation',
      label: 'Репрезентация глагола',
      children: verbRepresentationMap.get(record.verbRepresentation),
    },
    {
      key: 'participleType',
      label: 'Тип причастия',
      children: participleTypeMap.get(record.participleType),
    },
    {
      key: 'voice',
      label: 'Залог причастия',
      children: voiceMap.get(record.voice),
    },
    {
      key: 'tense',
      label: 'Время',
      children: tenseMap.get(record.tense),
    },
    {
      key: 'grammaticalCase',
      label: 'Падеж',
      children: grammaticalCaseMap.get(record.grammaticalCase),
    },
    {
      key: 'grammaticalGender',
      label: 'Род',
      children: grammaticalGenderMap.get(record.grammaticalGender),
    },
    {
      key: 'grammaticalNumber',
      label: 'Число',
      children: grammaticalNumberMap.get(record.grammaticalNumber),
    },
    {
      key: 'grammaticalPerson',
      label: 'Лицо',
      children: grammaticalPersonMap.get(record.grammaticalPerson),
    },
    {
      key: 'degreeOfComparison',
      label: 'Степень сравнения',
      children: degreeOfComparisonMap.get(record.degreeOfComparison),
    },
    {
      key: 'pronounType',
      label: 'Разряд местоимения',
      children: pronounTypeMap.get(record.pronounType),
    },
    {
      key: 'numeralType',
      label: 'Разряд числительного',
      children: numeralTypeMap.get(record.numeralType),
    },
    {
      key: 'grammaticalMood',
      label: 'Наклонение глагола',
      children: grammaticalMoodMap.get(record.grammaticalMood),
    },
    {
      key: 'personalityCategory',
      label: 'Личность / неличность',
      children: personalityCategoryMap.get(record.personalityCategory),
    },
    {
      key: 'completenessConcisenessCategory',
      label: 'Полнота / краткость',
      children: completenessConcisenessCategoryMap.get(
        record.completenessConcisenessCategory
      ),
    },
  ];
  const filteredRecordFields: DescriptionsItemType[] = recordFields
    .filter(({key}) => !(hiddenFields as string[]).includes(key));

  const status: DescriptionsItemType = {
    key: 'status',
    label: 'Статус',
    children: statusMap.get(record.status),
  };

  const navigate = useNavigate();
  const switchToAllForms = () => {
    const lemma = encodeURIComponent(record.lemma);
    const partOfSpeech = encodeURIComponent(record.partOfSpeech);
    const params = `lemma=${lemma}&stringMatchingMode=iexact&partOfSpeech=${partOfSpeech}`;
    navigate(`/morphology/dictionary/search?${params}`);
  };

  return (
    <div>
      <Descriptions
        column={2}
        layout="vertical"
        items={filteredRecordFields}
        style={{ flex: 1 }}
      />
      <Descriptions
        column={1}
        layout="vertical"
        items={[status]}
        contentStyle={{ display: 'block' }}
        style={{ flex: 1 }}
      />
      <div style={{textAlign: 'center'}}>
        <Button onClick={switchToAllForms}>Посмотреть все формы слова</Button>
      </div>
    </div>
  );
};
