import {
  DateRange,
  centuryPartLabelMap,
  Manuscript,
  WHOLE_CENTURY_RANGE,
} from './types';

export const toRomanNumber = (value: number): string => {
  if (!Number.isFinite(value)) {
    console.log(`toRomanNumber: received bad number ${value}`);
    return '';
  }
  if (!Number.isInteger(value)) {
    console.log(`toRomanNumber: received float ${value}`);
    return '';
  }
  if (value < 0) {
    console.log(`toRomanNumber: received negative number ${value}`);
    return '';
  }
  if (value === 0) return '';
  if (value >= 1000) return 'M' + toRomanNumber(value - 1000);
  if (value >= 900) return 'CM' + toRomanNumber(value - 900);
  if (value >= 500) return 'D' + toRomanNumber(value - 500);
  if (value >= 400) return 'CD' + toRomanNumber(value - 400);
  if (value >= 100) return 'C' + toRomanNumber(value - 100);
  if (value >= 90) return 'XC' + toRomanNumber(value - 90);
  if (value >= 50) return 'L' + toRomanNumber(value - 50);
  if (value >= 40) return 'XL' + toRomanNumber(value - 40);
  if (value >= 10) return 'X' + toRomanNumber(value - 10);
  if (value >= 9) return 'IX' + toRomanNumber(value - 9);
  if (value >= 5) return 'V' + toRomanNumber(value - 5);
  if (value >= 4) return 'IV' + toRomanNumber(value - 4);
  return 'I' + toRomanNumber(value - 1);
};

const getCentury = (d: DateRange) => {
  return d.exactDateYear != null
    ? Math.ceil(d.exactDateYear / 100)
    : d.firstCentury;
};

export const getHistoricDate = (date: DateRange) => {
  if (date.exactDateYear != null) {
    return String(date.exactDateYear);
  }
  if (date.firstCentury == null) {
    return 'Не указано';
  }
  const firstPrefix = centuryPartLabelMap.get(date.firstCenturyPrefix);
  const lastPrefix = date.lastCenturyPrefix
    ? centuryPartLabelMap.get(date.lastCenturyPrefix)!.toLowerCase()
    : '';
  const firstCentury = toRomanNumber(date.firstCentury);
  if (date.lastCentury === date.firstCentury) {
    return `${firstPrefix} - ${lastPrefix} ${firstCentury} века`;
  }

  const firstPart =
    date.firstCenturyPrefix === WHOLE_CENTURY_RANGE
      ? `${firstCentury} век`
      : `${firstPrefix} ${firstCentury} века`;
  if (!date.lastCentury) {
    return firstPart;
  }
  const lastCentury = toRomanNumber(date.lastCentury);
  const lastPart =
    date.lastCenturyPrefix === WHOLE_CENTURY_RANGE
      ? `${lastCentury} век`
      : `${lastPrefix} ${lastCentury} века`;
  return `${firstPart} - ${lastPart}`;
};

export const historicDateSorter = (a: DateRange, b: DateRange) => {
  // обработка случаев, когда хотя бы одна из датировок не указана
  const aIsUnknown = a.exactDateYear == null && a.firstCentury == null;
  const bIsUnknown = b.exactDateYear == null && b.firstCentury == null;
  if (aIsUnknown && bIsUnknown) {
    // если у обоих не указана сортировка, то они равны
    return 0;
  }
  if (aIsUnknown) {
    // если у `a` указана сортировка, а у `b` нет, то `b` первее `a`
    return 1;
  }
  if (bIsUnknown) {
    // если у `b` указана сортировка, а у `a` нет, то `a` первее `b`
    return -1;
  }
  if (a.exactDateYear != null && b.exactDateYear != null) {
    // если у обоих указан год, то сравнение по году
    return a.exactDateYear - b.exactDateYear;
  }
  const aCentury = getCentury(a)!;
  const bCentury = getCentury(b)!;
  if (aCentury !== bCentury) {
    // если века не совпали, то первее тот, у кого век меньше
    return aCentury - bCentury;
  }
  // далее `a` и `b` входят в один век, но год указан максимум у одного из них
  if (a.exactDateYear != null) {
    // если у `a` год есть, а у `b` нет, то `a` первее `b`
    return -1;
  }
  if (b.exactDateYear != null) {
    // если у `b` год есть, а у `a` нет, то `b` первее `a`
    return 1;
  }
  // сравнение по префиксам для стабильности результатов
  return a.firstCenturyPrefix - b.firstCenturyPrefix;
};

export const getFullCipher = (
  manuscript: Pick<Manuscript, 'collection' | 'cipher'>
): string => {
  const { cipherPrefix } = manuscript.collection;
  const { cipher } = manuscript;
  if (cipherPrefix == null || cipher.startsWith(cipherPrefix)) {
    return cipher;
  }
  return `${cipherPrefix} ${cipher}`;
};
