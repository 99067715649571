import {useLoaderData} from "react-router-dom";
import {PageTitle} from "../layout/PageTitle";
import {Panel} from "../layout/Panel";
import {ContentTitle} from "../layout/ContentTitle";
import {VyazTable} from "./VyazTable";
import { Manuscript } from "../manuscripts/types";
import { WithPagesWithVyaz } from "../manuscripts/types";
import { useMemo } from "react";

export const VyazSearchPage = () => {
  const manuscripts = useLoaderData() as WithPagesWithVyaz<Manuscript>[];
  const vyazCount = useMemo(() => {
    let result = 0;
    for (const manuscript of manuscripts) {
      result += manuscript.pages.length;
    }
    return result;
  }, [manuscripts]);
  return (
    <PageTitle title="Каталог вязи">
      <Panel>
        <div>
          <ContentTitle>Каталог вязи</ContentTitle>
          <br />
          Общее количество записей: {vyazCount}
        </div>
      </Panel>
      <Panel>
        <VyazTable
          manuscripts={manuscripts}
        />
      </Panel>
    </PageTitle>
  );
};
