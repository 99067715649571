import { type ColumnsType } from 'antd/es/table';
import type { Collection, DateRange, Manuscript, Repository } from './types';
import { monthMap, SplittedPagesStatus } from './types';
import { getFullCipher, getHistoricDate, historicDateSorter } from './utils';
import {Input, Select, Tooltip} from 'antd';
import { useLocalStorage } from '../utils';
import {SearchOutlined} from "@ant-design/icons";
import {useState} from "react";
import { TableWithLinks } from '../layout/TableWithLinks';


type RequiredFields =
  | 'id'
  | 'repository'
  | 'collection'
  | 'redaction'
  | 'months'
  | 'cipher';

type DataType = {
  key: React.Key;
} & Pick<Manuscript, RequiredFields> &
  DateRange;

const getColumns = (repositories: Repository[], manuscripts: Manuscript[]): ColumnsType<DataType> => [
  {
    title: 'Хранилище',
    dataIndex: 'repository',
    key: 'repository',
    render: (repository: Repository) => (
      <Tooltip placement="bottomLeft" title={repository.name}>
        {repository.shortName}
      </Tooltip>
    ),
    filters: repositories.map((repository) => ({
      text: repository.shortName,
      value: repository.id,
    })),
    onFilter: (value, record: DataType) => record.repository.id === value,
    sorter: (a, b) => a.repository.name.localeCompare(b.repository.name),
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Фонд, собрание, коллекция',
    dataIndex: 'collection',
    key: 'collection',
    render: (collection: Collection) => collection.name,
    filters: repositories.map((repository) => ({
      text: repository.shortName,
      value: `repository-${repository.id}`,
      children: repository.collections.map((collection) => ({
        text: collection.name,
        value: collection.id,
      })),
    })),
    filterMode: 'tree',
    filterSearch: true,
    onFilter: (value, record: DataType) =>
      record.collection.id === value ||
      `repository-${record.repository.id}` === value,
  },
  {
    title: 'Шифр',
    key: 'fullCipher',
    render: (_, manuscript: DataType) => (
      <span style={{ cursor: 'pointer' }}>{getFullCipher(manuscript)}</span>
    ),
    filters: manuscripts.map((manuscript) => ({
      text: getFullCipher(manuscript),
      value: getFullCipher(manuscript),
    })),
    filterSearch: true,
    onFilter: (value, record: DataType) => getFullCipher(record) === value,
  },
  {
    title: 'Месяцы',
    dataIndex: 'months',
    key: 'months',
    render: (months: number[]) =>
      months.map((m) => monthMap.get(m)!).join(', '),
  },
  {
    title: 'Извод',
    dataIndex: 'redaction',
    key: 'redaction',
    render: (redaction) => redaction.name,
  },
  {
    title: 'Датировка',
    key: 'historicDate',
    render: (_, manuscript: DataType) => getHistoricDate(manuscript),
    sorter: historicDateSorter,
    sortDirections: ['ascend'],
  },
];

const usePageSize = () => useLocalStorage('ManuscriptsTable/pageSize', '10');

const linkToManuscript = (record: DataType) => `/manuscripts/${record.id}`;

const statusOptions = [
  {
    value: (
      (1 << SplittedPagesStatus.CUT_NOT_EXIST) +
      (1 << SplittedPagesStatus.CUT_EXIST) +
      (1 << SplittedPagesStatus.DONE) +
      (1 << SplittedPagesStatus.PROCESSING)
    ),
    label: "Все",
  },
  {
    value: 1 << SplittedPagesStatus.CUT_NOT_EXIST,
    label: "Развороты без разрезов",
  },
  {
    value: 1 << SplittedPagesStatus.CUT_EXIST,
    label: "Валидация разрезов",
  },
  {
    value: 1 << SplittedPagesStatus.PROCESSING,
    label: "Идёт разрезка",
  },
  {
    value: 1 << SplittedPagesStatus.DONE,
    label: "Разрезана на страницы",
  },
];

export const ManuscriptsTable = ({
  manuscripts,
  repositories,
}: {
  manuscripts: Manuscript[];
  repositories: Repository[];
}) => {
  const [chosenPageSize, setChosenPageSize] = usePageSize();
  const onShowSizeChange = (current: number, pageSize: number) => {
    setChosenPageSize(String(pageSize));
  };
  const columns = getColumns(repositories, manuscripts);
  const [globalFilter, setGlobalFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState(statusOptions[0].value);
  const filteredManuscripts = manuscripts.filter(
    (a) => {
      return  a.repository.shortName.includes(globalFilter) ||
        a.repository.name.includes(globalFilter) ||
        a.collection.name.includes(globalFilter) ||
        getFullCipher(a).includes(globalFilter) ||
        a.redaction.name.includes(globalFilter) ||
        getHistoricDate(a).includes(globalFilter) ||
        a.months.some((month) => monthMap.get(month)!.includes(globalFilter))
    }
  ).filter((a) => !statusFilter || (statusFilter & (1 << a.splittedPagesStatus)));

  const data: DataType[] = filteredManuscripts.map((manuscript) => {
    return {
      ...manuscript,
      key: manuscript.id,
    };
  });
  return (
    <div>
      <Input
        style={{display: 'flex', marginBottom:'5px'}}
        prefix={<SearchOutlined/>}
        allowClear
        placeholder='Поиск'
        value={globalFilter}
        onChange={e => setGlobalFilter(e.target.value)}
      />
      <Select
        options={statusOptions}
        defaultActiveFirstOption
        value={statusFilter}
        onChange={value => setStatusFilter(value)}
        style={{minWidth: 200}}
      />
      <TableWithLinks
        to={linkToManuscript}
        pagination={{
          position: ['bottomRight', 'topRight'],
          pageSize: Number(chosenPageSize),
          onShowSizeChange,
        }}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};
