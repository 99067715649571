import { useEffect } from "react";
import { WEBSITE_TITLE } from "../constants";

export const PageTitle = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  useEffect(function setHeadTitleOnMountOrTitleUpdate() {
    if ( title !== WEBSITE_TITLE) {
      document.title = `${title} - ${WEBSITE_TITLE}`;
    } else {
      document.title = WEBSITE_TITLE;
    }
  }, [title]);
  return <>{children}</>;
};
