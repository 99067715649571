import { InputNumber, Pagination } from "antd";
import { useState } from "react";

export const PaginationWithInput = ({
  current,
  total,
  onChange,
}: {
  current: number;
  total: number;
  onChange: (value: number) => void;
}) => {
  const [inputValue, setInputValue] = useState<string | number | null>(current);
  const finishInput = () => {
    const num = Number(inputValue);
    if (Number.isInteger(inputValue) && 0 <= num && num < total) {
      onChange(num);
    } else {
      setInputValue(current);
    }
  };
  return (
    <div
      style={{ marginBottom: 15, display: 'flex', justifyContent: 'center' }}
    >
      <Pagination
        current={current}
        total={total}
        showSizeChanger={false}
        pageSize={1}
        onChange={onChange}
      />
      <div>
        Перейти на стр.
        <InputNumber
          key={current}
          min={1}
          max={total}
          defaultValue={current}
          onChange={setInputValue}
          onBlur={finishInput}
          onKeyUp={(event) => event.code === 'Enter' && finishInput()}
          controls={false}
          style={{ marginLeft: 10, height: 30, width: 50 }}
        />
      </div>
    </div>
  );
};
