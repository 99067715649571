import { Button, Checkbox, Form, InputNumber, Select, Tree, type TreeProps } from "antd";
import { SlavicInput } from "../morph-dictionary/SlavicField";
import { monthOptions, type Manuscript } from "./types";
import { useState } from "react";

type FormFields = {
  query: string;
  firstCentury?: number;
  lastCentury?: number;
  excludeDateRangeUnknown: boolean;
  months: number[];
  manuscripts?: string[];
  searchMode: string;
};

function splitManuscripts<T>(
  manuscripts: Manuscript[],
  getKey: (manuscript: Manuscript) => string,
  getObject: (manuscript: Manuscript) => T,
): {[key: string]: T & {manuscripts: Manuscript[]}} {
  return manuscripts.reduce((obj, manuscript) => {
    const key = getKey(manuscript);
    if (key in obj) {
      obj[key].manuscripts.push(manuscript);
    } else {
      obj[key] = {...getObject(manuscript), manuscripts: [manuscript]};
    }
    return obj;
  }, {} as {[key: string]: T & {manuscripts: Manuscript[]}});
}

const cmpCiphers = (a: Manuscript, b: Manuscript) => {
  if (a.cipher < b.cipher) {
    return -1;
  }
  if (a.cipher > b.cipher) {
    return 1;
  }
  return 0;
};


const getTreeData = (manuscripts: Manuscript[], isDisabled: (manuscript: Manuscript) => boolean): TreeProps['treeData'] => {
  const repositoryId2manuscripts = splitManuscripts(manuscripts, m => String(m.repository.id), m => m.repository);
  return Object.values(repositoryId2manuscripts).map(({manuscripts, ...repository}) => {
    const collectionId2manuscripts = splitManuscripts(manuscripts, m => String(m.collectionId), m => m.collection);
    const repositoryChildren = Object.values(collectionId2manuscripts).map(({manuscripts, ...collection}) => {
      const collectionChildren = manuscripts.sort(cmpCiphers).map((manuscript) => ({
        title: `${manuscript.cipher} ${manuscript.specialName.trim()}`,
        key: manuscript.id,
        isLeaf: true,
        disabled: isDisabled(manuscript),
      }));
      return {
        title: collection.name,
        key: `c-${collection.id}`,
        children: collectionChildren,
      };
    });
    return {
      title: repository.shortName,
      key: `r-${repository.id}`,
      children: repositoryChildren,
    };
  });
};

const searchModes = [
  {
    label: "Точное совпадение",
    value: "strict",
  },
  {
    label: "Совпадение троек символов",
    value: "3-gram",
  },
  {
    label: "Совпадение четвёрок символов",
    value: "4-gram",
  },
  {
    label: "Совпадение пятёрок символов",
    value: "5-gram",
  },
];

export const SearchImagesForm = ({
  fontFamily,
  onSubmit,
  manuscripts,
  initialValue,
}: {
  fontFamily: string;
  manuscripts: Manuscript[];
  initialValue?: Partial<FormFields>;
  onSubmit: (fields: FormFields) => void;
}) => {
  const [checkedKeys, setCheckedKeys] = useState<string[]>();
  const handleCheckTreeNode: TreeProps['onCheck'] = (value) => {
    if (value instanceof Array) {
      setCheckedKeys(value as string[]);
    } else {
      setCheckedKeys(value.checked as string[]);
    }
  };
  const handleSelect: TreeProps['onSelect'] = (_, event) => {
    if (event.node.isLeaf) {
      window.open(`/manuscripts/${event.node.key}`, '_blank');
    }
  };
  const handleFinish = (values: FormFields) => {
    const params: FormFields = {...values, query: values.query.trim()};
    const ids = checkedKeys?.filter((key) => !key.startsWith('r-') && !key.startsWith('c-'));
    if (ids != null && ids.length > 0 && ids.length < manuscripts.length) {
      params.manuscripts = ids;
    }
    onSubmit(params);
  };
  const [form] = Form.useForm<FormFields>();
  const query = Form.useWatch('query', form);
  const firstCentury = Form.useWatch('firstCentury', form);
  const lastCentury = Form.useWatch('lastCentury', form);
  const excludeDateRangeUnknown = Form.useWatch('excludeDateRangeUnknown', form);
  const months = Form.useWatch('months', form);
  const isDisabled = (manuscript: Manuscript) => {
    if (excludeDateRangeUnknown && manuscript.firstCentury == null && manuscript.exactDateYear == null) {
      return true;
    }
    if (firstCentury != null) {
      if (manuscript.firstCentury != null && manuscript.firstCentury < firstCentury) {
        return true;
      }
      if (manuscript.exactDateYear != null && manuscript.exactDateYear <= (firstCentury - 1) * 100) {
        return true;
      }
    }
    if (lastCentury != null) {
      if (manuscript.lastCentury && lastCentury < manuscript.lastCentury) {
        return true;
      }
      if (manuscript.exactDateYear != null && lastCentury * 100 < manuscript.exactDateYear) {
        return true;
      }
    }
    if (months != null && months.length > 0) {
      let included = false;
      for (const month of manuscript.months) {
        if (months.includes(month)) {
          included = true;
          break;
        }
      }
      if (!included) {
        return true;
      }
    }
    return false;
  };
  return (
    <Form
      form={form}
      initialValues={{ searchMode: 'strict', ...initialValue }}
      onFinish={handleFinish}
    >
      <Form.Item<FormFields> label="Запрос" name="query">
        <SlavicInput style={{ fontFamily }} enableMode />
      </Form.Item>
      <Form.Item<FormFields> label="Тип поиска" name="searchMode">
        <Select options={searchModes} />
      </Form.Item>
      <span>Выбор рукописей</span>
      <div style={{ border: '1px solid #ccc', padding: 10, borderRadius: 5 }}>
        <span>Датировка</span>
        <div style={{ display: 'flex', gap: 15 }}>
          <Form.Item<FormFields> name="firstCentury">
            <InputNumber
              min={1}
              max={21}
              controls={false}
              style={{ width: 50 }}
            />
          </Form.Item>
          <Form.Item<FormFields> name="lastCentury">
            <InputNumber
              min={1}
              max={21}
              controls={false}
              style={{ width: 50 }}
            />
          </Form.Item>
          <Form.Item<FormFields>
            name="excludeDateRangeUnknown"
            valuePropName="checked"
          >
            <Checkbox>Исключить недатированные</Checkbox>
          </Form.Item>
        </div>
        <Form.Item<FormFields> label="Месяцы" name="months" style={{ flex: 1 }}>
          <Select mode="multiple" options={monthOptions} />
        </Form.Item>
        <Tree
          checkable
          treeData={getTreeData(manuscripts, isDisabled)}
          onCheck={handleCheckTreeNode}
          onSelect={handleSelect}
        />
      </div>
      <Button
        style={{ marginTop: 5 }}
        type="primary"
        htmlType="submit"
        disabled={query == null || query.trim().length <= 3}
      >
        Искать
      </Button>
    </Form>
  );
};
