import { IAnnotation } from 'react-mark-image';
import { API_ROOT } from '../constants';
import { ApiError } from '../layout/errors';
import { WithPagesWithVyaz, ManuscriptBase } from '../manuscripts/types';
import { VyazBase } from './types';

export const fetchManuscriptsWithVyaz = async (authToken: string): Promise<WithPagesWithVyaz<ManuscriptBase>[]> => {
  const response = await fetch(`${API_ROOT}/manuscripts/vyaz`, {
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (!response.ok) {
    throw new ApiError('vyaz fetching failed', response);
  }
  return await response.json();
};

export const fetchVyazRec = async (authToken: string, pageId: string): Promise<VyazBase> => {
  const response = await fetch(`${API_ROOT}/pages/${pageId}/vyaz`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    }
  });
  if (!response.ok) {
    throw new ApiError('vyaz record fetching failed', response);
  }
  return await response.json();
};

export const deleteVyazRec = async (authToken: string, pageId: string)=> {
  const response = await fetch(`${API_ROOT}/pages/${pageId}/vyaz`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    }
  });
  if (!response.ok) {
    throw new ApiError('vyaz record deleting failed', response);
  }
  return true;
};

export const saveVyaz = async (
  authToken: string, pageId: string, annotation: IAnnotation
): Promise<VyazBase> => {
  let vyaz: Omit<VyazBase, 'id'> =
  {
    name: annotation.data.text!,
    x: annotation.geometry.x,
    y: annotation.geometry.y,
    width: annotation.geometry.width,
    height: annotation.geometry.height,
  }

  const response = await fetch(`${API_ROOT}/pages/${pageId}/vyaz`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(vyaz),
  });
  if (!response.ok) {
    throw new ApiError('vyaz creation failed', response);
  }
  const data = await response.json();
  return {
    ...data,
    createdAt: new Date(data.createdAt),
  };
};
