import { message } from "antd";
import { useContext, useMemo } from "react";
import { IAnnotation } from "react-mark-image";
import { AnnotationTab } from "../AnnotationTab";
import { PageContext } from "../types";
import { getAuthToken } from "../../profile/utils";
import * as api from '../api';
import * as vapi from '../../vyaz/api';


export const VyazTab = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const {page, vyaz} = useContext(PageContext)!;
  const authToken = getAuthToken()!;
  const vyazAnnotations = useMemo<IAnnotation[]>(() => vyaz == null ? [] : [{
    data: {
      id: page.id,
      text: vyaz.name,
    },
    geometry: {
      type: 'Rectangle',
      x: vyaz.x,
      y: vyaz.y,
      width: vyaz.width,
      height: vyaz.height,
    }
  }], [page, vyaz]);
  const saveVyazAnnotation = async (annotations: IAnnotation[]) => {
    try{
      if (annotations.length > 1){
        messageApi.warning('Выделено более 1 фрагмента вязи, удалите лишние')
        return
      }
      if (annotations.length == 0){
        await vapi.deleteVyazRec(authToken, page.id)
        messageApi.success('Успешно были удалены все записи о вязи на данной странице')
        return;
      }
      const annotation = annotations[0]     //Берем одну единственную аннотацию, больше нет
      await vapi.saveVyaz(authToken, page.id, annotation!)
      messageApi.success(`Вязь "${annotation!.data.text!}" была успешно сохранена`)
      return;
    }
    catch{
      messageApi.error('Ошибка при сохранении аннотации')
    }
  }
  return (
    <>
      {contextHolder}
      <AnnotationTab src={api.getPageUrl(page)} defaultAnnotations={vyazAnnotations}
        onSave={(annotations) => saveVyazAnnotation(annotations)}
      />
    </>
  );
};
