import React from 'react';
import type { MorphDictionaryRecord } from './types';
import {
  completenessConcisenessCategoryMap,
  degreeOfComparisonMap,
  grammaticalCaseMap,
  grammaticalGenderMap,
  grammaticalMoodMap,
  grammaticalNumberMap,
  grammaticalPersonMap,
  numeralTypeMap,
  participleTypeMap,
  personalityCategoryMap,
  pronounTypeMap,
  tenseMap,
  verbRepresentationMap,
  voiceMap,
} from './types';

const nullIf = (
  map: Map<number, string>,
  value: number,
  nullValue: number = 0
) => (value === nullValue ? null : map.get(value));

export const RecordJoinedFields = ({
  record,
  fontFamily,
}: {
  record: MorphDictionaryRecord;
  fontFamily: string;
}) => {
  const style = { fontFamily };
  const {
    presentTenseForm,
    verbRepresentation,
    pronounType,
    numeralType,
    personalityCategory,
    grammaticalMood,
    participleType,
    voice,
    grammaticalPerson,
    tense,
    grammaticalCase,
    completenessConcisenessCategory,
    grammaticalGender,
    degreeOfComparison,
    grammaticalNumber,
  } = record;
  const stringPrefix = [
    presentTenseForm ? '' : null, // пустая строка для отображения сепаратора
    nullIf(verbRepresentationMap, verbRepresentation, 0),
    nullIf(pronounTypeMap, pronounType, 0),
    nullIf(numeralTypeMap, numeralType, 0),
    nullIf(personalityCategoryMap, personalityCategory, 0),
    nullIf(grammaticalMoodMap, grammaticalMood, 0),
    nullIf(participleTypeMap, participleType, 0),
    nullIf(voiceMap, voice, 0),
    nullIf(grammaticalPersonMap, grammaticalPerson, 0),
    nullIf(tenseMap, tense, 0),
    nullIf(grammaticalCaseMap, grammaticalCase, 0),
    nullIf(
      completenessConcisenessCategoryMap,
      completenessConcisenessCategory,
      0
    ),
    nullIf(grammaticalGenderMap, grammaticalGender, 0),
    nullIf(degreeOfComparisonMap, degreeOfComparison, 0),
    nullIf(grammaticalNumberMap, grammaticalNumber, 0),
  ]
    .filter((value) => value != null)
    .join(', ');
  return (
    <>
      <span style={style}>{presentTenseForm}</span>
      {stringPrefix}
    </>
  );
};
