import {NavLink, RouteObject, useLocation} from 'react-router-dom';
import {
  FontColorsOutlined, HomeOutlined,
  ReadFilled, FontSizeOutlined, SearchOutlined
} from '@ant-design/icons';
import type { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { Menu, MenuProps} from 'antd';
import { MorphDictionarySearchPage } from '../morph-dictionary/pages';
import {ManuscriptsPage, SearchSnippetsPage, loadManuscripts, loadVyaz} from '../manuscripts/pages';
import {HomePage} from "../layout/HomePage";
import { VyazSearchPage } from "../vyaz/VyazSearchPage"
import React from "react";

// Свойство `path` обязательно
type RequiredPathRouteObject = RouteObject &
  Required<Pick<RouteObject, 'path'>>;

// Убирает свойство `key`, оно далее будет сопоставлено со значением `path`
type MenuItemProps = Omit<MenuItemType, 'key'>;

// Роуты с параметрами пункта меню
type MenuRoute = RequiredPathRouteObject & { menuProps: MenuItemProps };

// Список основных роутов, ссылки на которые приведены в меню.
// У каждого заданы параметры пункта меню
export const menuRoutes: MenuRoute[] = [
  {
    path: '/',
    element: <HomePage/>,
    loader: loadManuscripts,
    menuProps: {
      icon: <HomeOutlined />,
      label: 'Главная',
    },
  },
  {
    path: '/manuscripts',
    element: <ManuscriptsPage />,
    loader: loadManuscripts,
    menuProps: {
      icon: <ReadFilled />,
      label: 'Каталог рукописей',
    },
  },
  {
    path: '/vyaz',
    element: <VyazSearchPage />,
    loader: loadVyaz,
    menuProps: {
      icon: <FontSizeOutlined />,
      label: 'Каталог вязи',
    },
  },
  {
    path: '/manuscripts/search',
    element: <SearchSnippetsPage />,
    loader: loadManuscripts,
    shouldRevalidate: () => false,
    menuProps: {
      icon: <SearchOutlined />,
      label: 'Поиск',
    },
  },
  {
    path: '/morphology/dictionary',
    element: <MorphDictionarySearchPage />,
    menuProps: {
      icon: <FontColorsOutlined />,
      label: 'Словарь',
    },
  },
];

// Оборачивает label в ссылку на соответствующий роут и приводит к нужному формату
const convertMenuProps = (
  path: string,
  { label, ...props }: MenuItemProps
): { label: React.JSX.Element; key: string } & Omit<MenuItemProps, 'label'> => ({
  label: <NavLink to={path}>{label}</NavLink>,
  key: path,
  ...props,
});

// Пункты меню, которые ссылаются на роуты
const menuLinks: MenuItemType[] = menuRoutes.map(({ path, menuProps }) =>
  convertMenuProps(path, menuProps)
);

export const useMenuLinks = () => menuLinks;

const menuRouteKeys: React.Key[] = menuLinks.map((key: any) => key);

export const RoutedMenu = (props: Omit<MenuProps, 'selectedKeys'>) => {
  const { pathname } = useLocation();
  const selectedKeys = menuRouteKeys.includes(pathname) ? [pathname] : [];
  return <Menu selectedKeys={selectedKeys} {...props} />;
};
