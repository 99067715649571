import CSS from 'csstype';
import { Panel } from './Panel';
import defaultPreviewUrl from '../images/default-preview.png';
import { PageTitle } from './PageTitle';
import { useLoaderData } from 'react-router-dom';
import { ManuscriptPreview } from '../manuscripts/ManuscriptPreview';
import { THUMBNAILS_ROOT } from '../constants';
import { getFullCipher, getHistoricDate } from '../manuscripts/utils';
import { Empty } from 'antd';
import { Manuscript } from '../manuscripts/types';

const contentStyle: CSS.Properties = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
};

export const HomePage = () => {
  const allManuscripts = useLoaderData() as Manuscript[];
  const lastManuscripts = allManuscripts
    .sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0
    )
    .slice(0, 20);
  const previews = lastManuscripts.map((manuscript) => (
    <ManuscriptPreview
      thumbnail={
        manuscript.thumbnail == null
          ? defaultPreviewUrl
          : `${THUMBNAILS_ROOT}/${manuscript.thumbnail}`
      }
      title={`${manuscript.repository.shortName} ${getFullCipher(manuscript)}`}
      historicDate={getHistoricDate(manuscript)}
      createdAt={manuscript.createdAt}
      key={manuscript.id}
      splittedPagesStatus={manuscript.splittedPagesStatus}
      uuid={manuscript.id}
    />
  ));
  return (
    <PageTitle title="Главная">
      <Panel style={contentStyle}>
        {allManuscripts.length === 0 && <Empty />}
        {previews}
      </Panel>
    </PageTitle>
  );
};
