import { MessageInstance } from "antd/es/message/interface";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { Manuscript } from "./types";
import * as api from './api';

export const useProcessManuscript = (manuscript: Manuscript, authToken: string, messageApi: MessageInstance) => {
  const navigate = useNavigate();
  const timerRef = useRef<ReturnType<typeof setInterval>>();
  const [manuscriptProcResult, setManuscriptProcResult] =
  useState<number>(0);
  const fetchStatus = useCallback(async () => {
    try {
      const result = await api.getManuscriptProc(authToken, manuscript.id);
      setManuscriptProcResult(result);
      if (result !== 3) {
        return;
      }
      if (result === 3 && manuscriptProcResult !== 3) {
        navigate(0);
      }
      clearInterval(timerRef.current);
    } catch (error) {
      messageApi.error('Ошибка при получении данных с сервера');
      clearInterval(timerRef.current);
    }
    // authToken и messageApi добавлены, чтобы линтер не ругался
  }, [manuscript, manuscriptProcResult, authToken, messageApi]);
  useEffect(
    function tryLoadCurrentManuscript() {
      clearInterval(timerRef.current);
      setManuscriptProcResult(manuscript.splittedPagesStatus);
      if (manuscript.splittedPagesStatus != 3) {
        fetchStatus();
        timerRef.current = setInterval(fetchStatus, 2 * 1000);
        return () => {
          clearInterval(timerRef.current);
        }
      }
    },
    [manuscript, fetchStatus]
  );
  const process = async () => {
    if (manuscriptProcResult === 2) {
      return;
    }
    setManuscriptProcResult(2);
    try {
      await api.startManuscriptPagesCutProcessing(authToken, manuscript.id);
      clearInterval(timerRef.current);
      setManuscriptProcResult(3);
      navigate(0);
    } catch (error) {
      messageApi.error('Ошибка при отправке данных на сервер');
      setManuscriptProcResult(1);
    }
  };
  return {manuscriptProcResult, process};
};
