import "image-compare-viewer/dist/image-compare-viewer.min.css"
import ImageCompare from "image-compare-viewer";
import { useEffect, useRef } from 'react';

export const CompareImageView = ({
  topSrc,
  bottomSrc,
}: {
  topSrc: string;
  bottomSrc: string;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let view = new ImageCompare(containerRef.current, options);
    view.mount();
  }, []);
  // Опции для отображения ImageCompare
  const options = {

    // Опции темы UI

    controlColor: "#666",
    controlShadow: false,
    addCircle: true,
    addCircleBlur: true,

    // Опции отображения надписей

    showLabels: false,
    labelOptions: {
      before: 'Before',
      after: 'After',
      onHover: false
    },

    // Smoothing

    smoothing: true,
    smoothingAmount: 100,

    // Other options

    hoverStart: false,
    verticalMode: true,
    startingPoint: 0,
    fluidMode: false
  };

  return (
    <div ref={containerRef} className="image-compare">
      <img src={topSrc} alt=""/>
      <img src={bottomSrc} alt=""/>
    </div>
  );
}
