import { FileAddOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Spin, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import { useState } from 'react';

export const LoadImagesForm = ({
  onUpload,
}: {
  onUpload: (files: File[]) => void;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState<RcFile[]>([]);
  const addFile = (file: RcFile) => {
    setFiles((oldFiles) => [...oldFiles, file]);
    return false;
  };
  const removeFile = (file: UploadFile) => {
    setFiles((oldFiles) => oldFiles.filter(({ uid }) => uid !== file.uid));
    return true;
  };
  const submit = () => {
    setIsUploading(true);
    onUpload(files);
  };
  return (
    <div style={{ textAlign: 'left', display: 'inline-block' }}>
      {isUploading ? 'Файлы загружаются' : 'Файлы не загружены'}
      {isUploading && <Spin style={{marginLeft: 10}} />}
      <div style={{ margin: '10px 0' }}>
        <Upload
          multiple={true}
          showUploadList={files.length < 10}
          accept="image/*,application/pdf"
          beforeUpload={addFile}
          listType="picture"
          onRemove={removeFile}
          disabled={isUploading}
        >
          <Button icon={<FileAddOutlined />} disabled={isUploading}>
            Выбрать файлы
          </Button>
        </Upload>
      </div>
      {files.length > 0 && (
        <Button
          type="primary"
          icon={<UploadOutlined />}
          onClick={submit}
          disabled={isUploading}
        >
          Загрузить файлы
        </Button>
      )}
    </div>
  );
};
