import {
  Tabs,
} from 'antd';
import {
  BlockOutlined,
  FileTextFilled,
  FileTextOutlined,
  FontColorsOutlined,
  PictureFilled,
  SunOutlined,
} from '@ant-design/icons';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { SplitImageTab } from './tabs/SplitImageTab';
import { OriginalTab } from './tabs/OriginalTab';
import { VizualizationImageTab } from './tabs/VizualizationImageTab';
import { VyazTab } from './tabs/VyazTab';
import { TextTab } from './tabs/TextTab';
import { LoadImagesTab } from './tabs/LoadImagesTab';
import { ManuscriptMainTab } from './tabs/ManuscriptMainTab';
import  VyazIconUrl from '../images/Вязь.png';


/** Вспомогательный тип вкладки
 * Описывает, на какой из страниц отображать вкладку
 * и как на неё делать перенаправление с других страниц
 */
type SharedTab = {
  key: string;
  label: React.ReactNode;
  page?: React.ReactNode;
  image?: React.ReactNode;
  manuscript?: React.ReactNode;
  to: string;
}

/** Компонент, в котором для всех трёх страниц отрисовываются табы
 * Нужен, чтобы не разбредались табы между страницами, всё меняется в одном месте
 * Компонент управляет единственным состоянием activeKey, которое берёт из истории
 * Не помещай сюда ничего специфического для конкретной страницы
 * Всё специфическое следуют доставать изнутри вкладки
 * Можно, например, задействовать useContext для проброса данных
 */
export const ManuscriptTabs = ({
  type,
  pageNumber,
  imageNumber,
  manuscriptId,
  wrapper,
}: {
  type: 'manuscript'|'page'|'image';
  pageNumber: number | null;
  imageNumber: number | null;
  manuscriptId: string;
  wrapper?: (el: React.ReactNode, key: string) => React.ReactNode;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  let activeKey: string;
  const historyKey = location.state?.tab;
  switch (type) {
    case 'manuscript':
      if (['main', 'loadImages'].includes(historyKey)) {
        activeKey = historyKey;
      } else {
        activeKey = 'main';
      }
      break;
    case 'page':
      if (['original', 'binary', 'background', 'segments', 'classified', 'vyaz'].includes(historyKey)) {
        activeKey = historyKey;
      } else {
        activeKey = 'original';
      }
      break;
    case 'image':
      activeKey = 'split';
      break;
  }
  const switchTab = (nextActiveKey: string) => {
    if (nextActiveKey === activeKey) {
      return;
    }
    const nextTab = topTabs.find(({key}) => key === nextActiveKey);
    if (nextTab == null) {
      navigate('.', {state: { tab: nextActiveKey, pageNumber, imageNumber}, replace: true});
    } else if (nextTab.children == null) {
      navigate(nextTab.to, {state: { tab: nextActiveKey, pageNumber, imageNumber}});
    } else {
      navigate('.', {state: { tab: nextActiveKey, pageNumber, imageNumber}, replace: true});
    }
  };
  const mainTab: SharedTab = {
    key: 'main',
    label: 'Основная информация',
    manuscript: <ManuscriptMainTab />,
    to: `/manuscripts/${manuscriptId}`,
  };
  const loadImagesTab: SharedTab | null = imageNumber != null ? null : {
    key: 'loadImages',
    label: 'Загрузить изображения',
    manuscript: <LoadImagesTab />,
    to: `/manuscripts/${manuscriptId}`,
  };
  const splitImageTab: SharedTab | null = imageNumber == null ? null : {
    key: 'split',
    label: 'Развороты',
    image: <SplitImageTab />,
    to: `/manuscripts/${manuscriptId}/images/${imageNumber}`,
  };
  const originalTab = {
    key: 'original',
    label: <PictureFilled title="Оригинал" />,
    children: <OriginalTab />,
  };
  const vizualizationTabs = ([
    {
      key: 'binary',
      label: <SunOutlined title="Чёрно-белое" />,
      field: 'binary',
    },
    {
      key: 'background',
      label: <FileTextFilled title="Фон" />,
      field: 'background',
    },
    {
      key: 'segments',
      label: <FileTextOutlined title="Сегменты" />,
      field: 'segments',
    },
    {
      key: 'classified',
      label: <FontColorsOutlined title="Классы" />,
      field: 'classified',
    }
  ] as const).map(({key, label, field}) => ({
    key,
    label,
    children: <VizualizationImageTab field={field} />,
  }));
  const vyazTab = {
    key: 'vyaz',
    label: <img src={VyazIconUrl} style={{height: '20px'}}/>,
    children: <VyazTab />,
  }
  const textSubTabs = [originalTab, ...vizualizationTabs, vyazTab];
  const activeKeyForTextSubTabs = textSubTabs.find(({key}) => key === activeKey)?.key || 'original';
  const textSubTabsEl = (
    <Tabs
      items={textSubTabs}
      tabPosition='left'
      activeKey={activeKeyForTextSubTabs}
      onChange={switchTab}
    />
  );
  const textTab: SharedTab | null = pageNumber == null ? null : {
    key: 'text',
    label: 'Страницы',
    page: (
      <TextTab subTab={textSubTabsEl} />
    ),
    to: `/manuscripts/${manuscriptId}/pages/${pageNumber}`,
  };
  const topTabs = ([mainTab, loadImagesTab, splitImageTab, textTab]
    .filter(tab => tab != null) as SharedTab[])
    .map(({key, label, to, ...otherProps}: SharedTab) => {
      let children = otherProps[type];
      if (children != null && wrapper != null) {
        children = wrapper(children, activeKey);
      }
      return {
      key,
      label,
      to,
      children,
    }
  });
  let topActiveKey: string;
  if (textSubTabs.findIndex(({key}) => key === activeKey) != -1) {
    topActiveKey = 'text';
  } else {
    topActiveKey = activeKey;
  }
  return (
    <Tabs
      items={topTabs}
      activeKey={topActiveKey}
      onChange={switchTab}
    />
  );
};
