import { ConfigProvider, Table, type TableProps } from 'antd';
import { Link } from 'react-router-dom';

type TableWithLinksProps<RecordType> = {
  to: (record: RecordType, index: keyof RecordType) => string | null;
} & TableProps<RecordType>;

export function TableWithLinks<RecordType extends Record<PropertyKey, any>>({
  to,
  columns,
  ...props
}: TableWithLinksProps<RecordType>) {
  columns = columns?.map((column) => ({
    ...column,
    render: (value, record, index) => {
      const link = to(record, index);
      const children = column.render?.(value, record, index) ?? value;
      if (link == null) {
        return <span>children</span>;
      }
      return (
        <Link
          style={{ padding: 16, display: 'block', color: 'inherit' }}
          to={link}
        >
          {children}
        </Link>
      );
    },
  }));
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            cellPaddingBlock: 0,
            cellPaddingInline: 0,
            cellPaddingBlockMD: 0,
            cellPaddingInlineMD: 0,
            cellPaddingBlockSM: 0,
            cellPaddingInlineSM: 0,
          },
        },
      }}
    >
      <Table<RecordType> columns={columns} {...props} />
    </ConfigProvider>
  );
}
