import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { Rule } from 'antd/es/form';
import { Token } from './types';

export const getAuthToken = () => {
  const serializedToken = localStorage.getItem('authToken');
  if (!serializedToken) {
    return null;
  }
  try {
    const {token, expires}: Token = JSON.parse(serializedToken);
    const expiredDate = new Date(expires);
    // для уверенности вычтем 1 день
    expiredDate.setDate(expiredDate.getDate() - 1);
    const now = new Date();
    if (now < expiredDate) {
      return token;
    }
    localStorage.removeItem('authToken');
    return null;
  } catch(exc) {
    localStorage.removeItem('authToken');
    return null;
  }
};

const withNext = (targetPathname: string) => (nextUrl: string) => {
  if (!nextUrl.startsWith('/')) {
    try {
      const url = new URL(nextUrl);
      nextUrl = url.pathname + url.search + url.hash;
    } catch (e) {
      return targetPathname;
    }
  }
  if (nextUrl === '/') {
    return targetPathname;
  }
  return `${targetPathname}?next=${encodeURIComponent(nextUrl)}`;
};

export const buildLoginUrl = withNext('/login');

export const buildRegisterUrl = withNext('/register');

export const getNextUrl = (currentUrl: string) => {
  const url = new URL(currentUrl);
  const next = url.searchParams.get('next');
  if (next == null || !next.startsWith('/')) {
    return '/';
  }
  return next;
}

export const redirectUnauthorizedUser = ({ request }: LoaderFunctionArgs) => {
  if (getAuthToken() != null) {
    return null;
  }
  return redirect(buildLoginUrl(request.url));
};

export const redirectAuthorizedUser = ({ request }: LoaderFunctionArgs) => {
  if (getAuthToken() == null) {
    return null;
  }
  const next = getNextUrl(request.url);
  return redirect(next);
};

export const emailRule: Rule = {
  pattern: /.+@.+/,
  message: 'Невалидный адрес электронной почты',
};

export const passwordRules: Rule[] = [
  { min: 8, message: 'Пароль должен быть не менее 8 символов' },
  { pattern: /[0-9]/, message: 'Должна быть минимум одна цифра' },
  { pattern: /[a-zA-Zа-яёА-ЯЁ]/, message: 'Должна быть минимум одна буква' },
];

export const passwordRepeatRule =
  (passwordFieldName: string): Rule =>
  ({ getFieldValue }) => ({
    message: 'Пароли не совпадают',
    validator(_, value) {
      if (!value || getFieldValue(passwordFieldName) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Пароли не совпадают'));
    },
  });
