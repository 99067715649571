import React from 'react';
import { Button, Checkbox, ConfigProvider, Form, Input, Select, Space } from 'antd';
import type { MorphDictionaryRecord } from './types';
import {
  partOfSpeechOptions,
  participleTypeOptions,
  pronounTypeOptions,
  degreeOfComparisonOptions,
  grammaticalGenderOptions,
  statusOptions,
  verbRepresentationOptions,
  voiceOptions,
  tenseOptions,
  grammaticalCaseOptions,
  grammaticalNumberOptions,
  grammaticalPersonOptions,
  numeralTypeOptions,
  grammaticalMoodOptions,
  personalityCategoryOptions,
  completenessConcisenessCategoryOptions,
} from './types';
import { SlavicInput } from './SlavicField';

const requiredFieldRule = { required: true, message: 'Поле обязательно' };

export type EditFormValues = Omit<MorphDictionaryRecord, 'id'> & {
  multipleUpdateStatus: boolean;
  multipleUpdateComment: boolean;
  multipleUpdateCompleteness: boolean;
};

type ItemProps = React.ComponentProps<typeof Form.Item<EditFormValues>>;

const InputField = (props: ItemProps) => (
  <Form.Item<EditFormValues> {...props}>
    <Input />
  </Form.Item>
);

type Option = {
  value: number;
  label: string;
};

const SelectField = ({
  options,
  defaultActiveFirstOption,
  ...props
}: ItemProps & { options: Option[], defaultActiveFirstOption?: boolean }) => (
  <Form.Item<EditFormValues> {...props}>
    <Select
      options={options}
      defaultActiveFirstOption={defaultActiveFirstOption} />
  </Form.Item>
);

type MorphDictionaryEditFormProps = {
  fontFamily: string;
  initialValue?: Partial<MorphDictionaryRecord>;
  onCancel: () => void;
  onSave: (value: EditFormValues) => void;
};

type RequiredFields = 'lemma'|'word'|'partOfSpeech'|'status'
type OptionalFields = Exclude<keyof EditFormValues, RequiredFields>;
type Result = Pick<EditFormValues, RequiredFields> & Partial<Pick<EditFormValues, OptionalFields>>;

const defaultValues: Pick<EditFormValues, OptionalFields> = {
  multipleUpdateStatus: false,
  multipleUpdateComment: false,
  multipleUpdateCompleteness: false,
  grammaticalCase: 0,
  grammaticalMood: 0,
  grammaticalGender: 0,
  grammaticalNumber: 0,
  grammaticalPerson: 0,
  degreeOfComparison: 0,
  completenessConcisenessCategory: 0,
  personalityCategory: 0,
  verbRepresentation: 0,
  tense: 0,
  participleType: 0,
  pronounType: 0,
  numeralType: 0,
  voice: 0,
  presentTenseForm: '',
  serviceComment: '',
  comment: '',
} as const;

export const MorphDictionaryEditForm = ({
  fontFamily,
  initialValue,
  onCancel,
  onSave,
}: MorphDictionaryEditFormProps) => {
  const [form] = Form.useForm<Omit<EditFormValues, 'id'>>();
  const partOfSpeech = Form.useWatch('partOfSpeech', form);
  const verbRepresentation = Form.useWatch('verbRepresentation', form);
  const grammaticalMood = Form.useWatch('grammaticalMood', form);
  const numeralType = Form.useWatch('numeralType', form);
  const pronounType = Form.useWatch('pronounType', form);
  const personalityCategory = Form.useWatch('personalityCategory', form);
  const withGrammaticalCase = verbRepresentation === 4 || [5, 7, 8, 12].includes(partOfSpeech);
  const withGrammaticalGender = numeralType === 3 || verbRepresentation === 4 || pronounType === 2 || [7, 8].includes(partOfSpeech);
  const withGrammaticalNumber = numeralType === 3 || [3, 4].includes(verbRepresentation) ||[7, 8].includes(partOfSpeech) || [1, 2].includes(pronounType);
  const withGrammaticalPerson = verbRepresentation === 3 || personalityCategory === 1;
  const withCompletenessConcisenessCategory = verbRepresentation === 4 || partOfSpeech === 7;
  const handleFinish = (value: Result) => {
    onSave({...defaultValues, ...value});
  }
  const result = (
    <Form
      onFinish={handleFinish}
      initialValues={initialValue}
      layout="vertical"
      form={form}>
      <Space
        wrap
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'stretch',
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          style={{ backgroundColor: '#080' }}
        >
          Сохранить
        </Button>
        <Button type="primary" htmlType="button" onClick={onCancel}>
          Отмена
        </Button>
      </Space>
      <Form.Item<EditFormValues>
        label="Лемма"
        name="lemma"
        rules={[requiredFieldRule]}
      >
        <SlavicInput style={{ fontFamily }}/>
      </Form.Item>
      <Form.Item<EditFormValues>
        label="Слово"
        name="word"
        rules={[requiredFieldRule]}
      >
        <SlavicInput style={{ fontFamily }} enableMode />
      </Form.Item>
      <Space
        wrap
        style={{ alignItems: 'start', width: '100%' }}
        styles={{ item: { flex: 1, display: 'flex' } }}
      >
        <Space wrap direction="vertical" style={{ flex: 1 }}>
          <SelectField
            label="Часть речи"
            name="partOfSpeech"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={partOfSpeechOptions}
          />
          {partOfSpeech === 1 && (
            <Form.Item<EditFormValues>
              label="Форма настоящего времени"
              name="presentTenseForm"
            >
              <SlavicInput style={{ fontFamily }} enableMode />
            </Form.Item>
          )}
          {partOfSpeech === 1 && <SelectField
            label="Репрезентация глагола"
            name="verbRepresentation"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={verbRepresentationOptions}
          />}
          {verbRepresentation === 4 && <SelectField
            label="Тип причастия"
            name="participleType"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={participleTypeOptions}
          />}
          {verbRepresentation === 4 && <SelectField
            label="Залог причастия"
            name="voice"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={voiceOptions}
          />}
          {verbRepresentation === 3 && <SelectField
            label="Наклонение глагола"
            name="grammaticalMood"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={grammaticalMoodOptions}
          />}
          {grammaticalMood === 1 && <SelectField
            label="Время"
            name="tense"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={tenseOptions}
          />}
          {withGrammaticalCase && <SelectField
            label="Падеж"
            name="grammaticalCase"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={grammaticalCaseOptions}
          />}
          {withGrammaticalGender && <SelectField
            label="Род"
            name="grammaticalGender"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={grammaticalGenderOptions}
          />}
          {withGrammaticalNumber && <SelectField
            label="Число"
            name="grammaticalNumber"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={grammaticalNumberOptions}
          />}
          {withGrammaticalPerson && <SelectField
            label="Лицо"
            name="grammaticalPerson"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={grammaticalPersonOptions}
          />}
          {[7, 10].includes(partOfSpeech) && <SelectField
            label="Степень сравнения"
            name="degreeOfComparison"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={degreeOfComparisonOptions}
          />}
          {partOfSpeech === 5 && <SelectField
            label="Разряд местоимения"
            name="pronounType"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={pronounTypeOptions}
          />}
          {partOfSpeech === 12 && <SelectField
            label="Разряд числительного"
            name="numeralType"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={numeralTypeOptions}
          />}
          {pronounType === 1 && <SelectField
            label="Личность / неличность"
            name="personalityCategory"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={personalityCategoryOptions}
          />}
          {withCompletenessConcisenessCategory && (
            <div>
              <SelectField
                label="Полнота / краткость"
                name="completenessConcisenessCategory"
                rules={[requiredFieldRule]}
                defaultActiveFirstOption
                options={completenessConcisenessCategoryOptions}
              />
              <Form.Item<EditFormValues> name="multipleUpdateCompleteness" valuePropName="checked">
                <Checkbox>Изменить для всех форм слова</Checkbox>
              </Form.Item>
            </div>
          )}
        </Space>
        <Space direction="vertical" style={{ flex: 1 }}>
          <SelectField
            label="Статус"
            name="status"
            rules={[requiredFieldRule]}
            defaultActiveFirstOption
            options={statusOptions}
          />
          {initialValue?.id != null && (
            <Form.Item<EditFormValues> name="multipleUpdateStatus" valuePropName="checked">
              <Checkbox>Изменить статус для всех форм слова</Checkbox>
            </Form.Item>
          )}
          <InputField label="Комментарий к лемме" name="comment" />
          {initialValue?.id != null && (
            <Form.Item<EditFormValues> name="multipleUpdateComment" valuePropName="checked">
              <Checkbox>Изменить комментарий к лемме для всех форм слова</Checkbox>
            </Form.Item>
          )}
          <InputField label="Комментарий к словоформе" name="serviceComment" />
        </Space>
      </Space>
    </Form>
  );
  return (
    <ConfigProvider theme={{components: {Form: {itemMarginBottom: 0}}}}>
      {result}
    </ConfigProvider>
  );
};
