import { type RouteObject } from 'react-router-dom';
import App from '../App';
import { menuRoutes } from './menu';
import {
  MorphDictionaryCreateRecordPage,
  MorphDictionaryRecordPage,
  MorphDictionaryTablePage,
  loadMorphDictionaryRecord,
  loadMorphDictionaryRecords
} from '../morph-dictionary/pages';
import {
  ManuscriptCreatePage,
  loadCachedDataForManuscripts,
  loadManuscriptWithPagesAndImages,
  loadVyazForPage,
} from '../manuscripts/pages';
import { ManuscriptPage } from '../manuscripts/ManuscriptPage';
import { LoginPage, RegisterPage } from '../profile/pages';
import { redirectAuthorizedUser, redirectUnauthorizedUser } from '../profile/utils';
import { PageForPage } from '../manuscripts/PageForPage';
import { ImagePage } from '../manuscripts/ImagePage';

const childRoutes: RouteObject[] = [
  {
    path: '/morphology/dictionary/search',
    element: <MorphDictionaryTablePage />,
    loader: loadMorphDictionaryRecords,
  },
  {
    path: '/morphology/dictionary/new',
    element: <MorphDictionaryCreateRecordPage />,
  },
  {
    path: '/morphology/dictionary/:recordId',
    element: <MorphDictionaryRecordPage />,
    loader: loadMorphDictionaryRecord,
  },
  {
    path: '/manuscripts/new',
    element: <ManuscriptCreatePage />,
    loader: loadCachedDataForManuscripts,
  },
  {
    id: "ManuscriptPage",
    path: '/manuscripts/:manuscriptId',
    loader: loadManuscriptWithPagesAndImages,
    children: [
      {
        index: true,
        element: <ManuscriptPage />,
      },
      {
        path: 'images/:imageNumber',
        element: <ImagePage />,
      },
      {
        path: 'pages/:pageNumber',
        element: <PageForPage />,
        loader: loadVyazForPage,
      },
    ]
  },
];

export const routes: RouteObject[] = [
  {
    element: <App />,
    // TODO: errorElement: <ErrorPage/>,
    children: childRoutes.concat(menuRoutes),
    loader: redirectUnauthorizedUser,
  },
  {
    element: <LoginPage />,
    path: '/login',
    loader: redirectAuthorizedUser,
  },
  {
    element: <RegisterPage />,
    path: '/register',
    loader: redirectAuthorizedUser,
  },
];
