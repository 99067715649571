import { Navigate, useLoaderData, useLocation, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { message } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { useCallback, useEffect, useRef, useState } from "react";
import { ImageProcResult, Manuscript, Page, PageContext, WithPages, WithImages } from "./types";
import { PaginationWithInput } from "../layout/PaginationWithInput";
import { PageTitle } from "../layout/PageTitle";
import { ContentTitle } from "../layout/ContentTitle";
import { Panel } from "../layout/Panel";
import { ManuscriptTabs } from "./ManuscriptTabs";
import { getFullCipher } from "./utils";
import { getAuthToken } from "../profile/utils";
import * as api from './api';
import { useProcessManuscript } from "./useProcessManuscript";
import { VyazBase } from "../vyaz/types";

/***
 * PagePage выглядит странно, Page и ManuscriptPage уже заняты, поэтому такое название
 */
export const PageForPage = () => {
  const manuscript = useRouteLoaderData(
    'ManuscriptPage'
  ) as WithImages<WithPages<Manuscript>>;
  const vyaz = useLoaderData() as VyazBase;
  const location = useLocation();
  const params = useParams();
  const imageNumber = manuscript.images.length === 0 ? null : (location.state?.imageNumber || 1);
  if (manuscript.pages.length === 0) {
    return <Navigate to={`/manuscripts/${manuscript.id}`} />;
  }
  if (params?.pageNumber == null) {
    return <Navigate to={`/manuscripts/${manuscript.id}`} />;
  }
  const pageNumber = parseInt(params?.pageNumber);
  if (manuscript.pages.length < pageNumber) {
    return <Navigate to={`/manuscripts/${manuscript.id}/pages/1`} />;
  }
  if (
    !Number.isInteger(pageNumber) ||
    pageNumber <= 0 ||
    manuscript.pages.length < pageNumber
  ) {
    return <Navigate to={`/manuscripts/${manuscript.id}`} />;
  }
  const title = getFullCipher(manuscript);
  const contentTitle = [
    manuscript.repository.shortName,
    manuscript.collection.name,
    manuscript.cipher,
  ].join(', ');
  return (
    <PageTitle title={title}>
      <Panel>
        <ContentTitle>{contentTitle}</ContentTitle>
      </Panel>
      <Panel>
        <PageTabs
          key={pageNumber}
          manuscript={manuscript}
          imageNumber={imageNumber}
          pageNumber={pageNumber}
          vyaz={vyaz}
        />
      </Panel>
    </PageTitle>
  );
};


const PageTabs = ({
  pageNumber,
  imageNumber,
  manuscript,
  vyaz,
}: {
  pageNumber: number;
  imageNumber: number;
  manuscript: WithPages<Manuscript>;
  vyaz: VyazBase;
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const authToken = getAuthToken()!;
  let page = manuscript.pages[pageNumber - 1];
  const { loading, processing, imageProcResult, process } = useProcessImage(
    page,
    authToken,
    messageApi
  );
  useProcessManuscript(manuscript, authToken, messageApi);
  if (imageProcResult != null) {
    page = imageProcResult; //Получаем значение из бд
  }
  const switchToPage = (newPageNumber: number) => {
    navigate(`/manuscripts/${manuscript.id}/pages/${newPageNumber}`);
  };
  const wrapper = (tab: React.ReactNode) => (
    <div style={{minHeight: '100vh'}}>
        <PaginationWithInput
          current={pageNumber}
          total={manuscript.pages.length}
          onChange={switchToPage}
        />
        {tab}
      </div>
  );
  return (
    <PageContext.Provider value={{page, manuscriptId: manuscript.id, loading, processing, process, vyaz}}>
      {contextHolder}
      <ManuscriptTabs
        key={pageNumber}
        type='page'
        manuscriptId={manuscript.id}
        imageNumber={imageNumber}
        pageNumber={pageNumber}
        wrapper={wrapper}
      />
    </PageContext.Provider>
  );
};

/**
 * loading показывает, загружаются ли результаты обработки getImageProc
 * processing показывает, обрабатывается ли сейчас изображение
 */

const useProcessImage = (page: Page, authToken: string, messageApi: MessageInstance) => {
  const timerRef = useRef<ReturnType<typeof setInterval>>();
  const [imageProcResult, setImageProcResult] =
  useState<ImageProcResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const loadImageProcResult = useCallback(async () => {
    try {
      const result = await api.getPageProc(authToken, page.id);
      setImageProcResult(result);
      if (!result) {
        return;
      }
      if (result.processingPipelineStartedAt) {
        return;
      }
      clearInterval(timerRef.current);
      setLoading(false);
      setProcessing(false);
    } catch (error) {
      messageApi.error('Ошибка при получении данных с сервера');
      clearInterval(timerRef.current);
      setLoading(false);
    }
  }, [page, messageApi]);
  useEffect(
    function tryLoadCurrentImage() {
      clearInterval(timerRef.current);
      setImageProcResult(null);
      setProcessing(false);
      setLoading(true);
      if (page.processingPipelineStartedAt != null) {
        setProcessing(true);
        timerRef.current = setInterval(loadImageProcResult, 5 * 1000);
        return () => {
          clearInterval(timerRef.current);
        };
      } else {
        loadImageProcResult().finally(() => {
          setLoading(false);
        });
      }
    },
    [page, loadImageProcResult]
  );
  const process = async () => {
    if (processing) {
      return;
    }
    setProcessing(true);
    try {
      await api.postImageProc(authToken, page.id);
      clearInterval(timerRef.current);
      setImageProcResult(null);
      setLoading(true);
      timerRef.current = setInterval(loadImageProcResult, 5 * 1000);
    } catch (error) {
      messageApi.error('Ошибка при отправке данных на сервер');
      setProcessing(false);
    }
  };
  return {loading, processing, imageProcResult, process};
}
