// Часть речи
export const partOfSpeechOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Глагол"},
  {"value": 2, "label": "Предикатив"},
  {"value": 3, "label": "Междометие"},
  {"value": 4, "label": "Предлог"},
  {"value": 5, "label": "Местоимение"},
  {"value": 6, "label": "Союз"},
  {"value": 7, "label": "Прилагательное"},
  {"value": 8, "label": "Существительное"},
  {"value": 9, "label": "Частица"},
  {"value": 10, "label": "Наречие"},
  {"value": 11, "label": "Число"},
  {"value": 12, "label": "Числительное"},
];

// Падеж
export const grammaticalCaseOptions = [
  {"value": 0, "label": "Не указан"},
  {"value": 1, "label": "Именительный"},
  {"value": 2, "label": "Родительный"},
  {"value": 3, "label": "Винительный"},
  {"value": 4, "label": "Дательный"},
  {"value": 5, "label": "Творительный"},
  {"value": 6, "label": "Звательный"},
  {"value": 7, "label": "Местный"},
];

// Род
export const grammaticalGenderOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Мужской"},
  {"value": 2, "label": "Женский"},
  {"value": 3, "label": "Средний"},
  // {"value": 4, "label": "Без рода"},
  {"value": 5, "label": "Общий (м-"},
];

// Степень сравнения
export const degreeOfComparisonOptions = [
  {"value": 0, "label": "Не указана"},
  {"value": 1, "label": "Положительная"},
  {"value": 2, "label": "Сравнительная"},
];

// Число
export const grammaticalNumberOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Единственное"},
  {"value": 2, "label": "Множественное"},
  {"value": 3, "label": "Двойственное"},
];

// Полнота/ краткость
export const completenessConcisenessCategoryOptions = [
  {"value": 0, "label": "Не указана"},
  {"value": 1, "label": "Полное"},
  {"value": 2, "label": "Краткое"},
];

// Лицо
export const grammaticalPersonOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Первое лицо"},
  {"value": 2, "label": "Второе лицо"},
  {"value": 3, "label": "Третье лицо"},
];

// Личность/ неличность
export const personalityCategoryOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Личное"},
  {"value": 2, "label": "Неличное"},
];

// Репрезентация глагола
export const verbRepresentationOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Супин"},
  {"value": 2, "label": "Инфинитив"},
  {"value": 3, "label": "Личная форма"},
  {"value": 4, "label": "Причастие"},
];

// Наклонение глагола
export const grammaticalMoodOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Изъявительное"},
  {"value": 2, "label": "Повелительное"},
  {"value": 3, "label": "Сослагательное"},
];

// Время
export const tenseOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Настоящее время"},
  //{"value": 2, "label": "Прошедшее время"},
  {"value": 3, "label": "Будущее время"},
  {"value": 4, "label": "Аорист"},
  {"value": 5, "label": "Имперфект"},
];

// Тип причастия
export const participleTypeOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Настоящее"},
  {"value": 2, "label": "Прошедшее"},
  {"value": 3, "label": "Будущее"},
  {"value": 4, "label": "Перфектное"},
];

// Залог (причастия)
export const voiceOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Действительный"},
  {"value": 2, "label": "Страдательный"},
];

// Разряд местоимения
export const pronounTypeOptions = [
  {"value": 0, "label": "Не указано"},
  {"value": 1, "label": "Субстантивное"},
  {"value": 2, "label": "Определительное"},
];

// Разряд числительного
export const numeralTypeOptions = [
  {"value": 0, "label": "Не указан"},
  {"value": 1, "label": "Собирательное"},
  {"value": 2, "label": "Количественное"},
  {"value": 3, "label": "Порядковое"},
];

// Статус
export const statusOptions = [
  {"value": 1, "label": "Опубликовано"},
  {"value": 2, "label": "Черновик"},
  {"value": 3, "label": "Валидация"},
  {"value": 4, "label": "Ошибка"},
  {"value": 5, "label": "Удалено"},
];

const map = (options: { value: number, label: string }[]): Map<number, string> => {
  const map = new Map();
  for (const {value, label} of options) {
    map.set(value, label);
  }
  return map;
};

export const partsOfSpeechMap = map(partOfSpeechOptions);
export const grammaticalCaseMap = map(grammaticalCaseOptions);
export const grammaticalGenderMap = map(grammaticalGenderOptions);
export const degreeOfComparisonMap = map(degreeOfComparisonOptions);
export const grammaticalNumberMap = map(grammaticalNumberOptions)
export const completenessConcisenessCategoryMap = map(completenessConcisenessCategoryOptions);
export const grammaticalPersonMap = map(grammaticalPersonOptions);
export const personalityCategoryMap = map(personalityCategoryOptions);
export const verbRepresentationMap = map(verbRepresentationOptions);
export const grammaticalMoodMap = map(grammaticalMoodOptions);
export const tenseMap = map(tenseOptions);
export const participleTypeMap = map(participleTypeOptions);
export const voiceMap = map(voiceOptions);
export const pronounTypeMap = map(pronounTypeOptions);
export const numeralTypeMap = map(numeralTypeOptions);
export const statusMap = map(statusOptions);

export type MorphDictionaryRecord = {
  id: string;
  status: number;
  lemma: string;
  word: string;
  partOfSpeech: number;
  grammaticalCase: number;
  grammaticalGender: number;
  degreeOfComparison: number;
  grammaticalNumber: number;
  completenessConcisenessCategory: number;
  grammaticalPerson: number;
  personalityCategory: number;
  verbRepresentation: number;
  grammaticalMood: number;
  tense: number;
  participleType: number;
  voice: number;
  pronounType: number;
  numeralType: number;
  comment: string;
  serviceComment: string;
  presentTenseForm: string;
}
