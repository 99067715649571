import {
  message,
} from 'antd';
import { Panel } from '../layout/Panel';
import { Manuscript, ManuscriptBase, ManuscriptContext, WithPages, WithImages } from './types';
import { getFullCipher } from './utils';
import * as api from './api';
import {
  useLocation,
  useNavigate,
  useRouteLoaderData,
} from 'react-router-dom';
import { PageTitle } from '../layout/PageTitle';
import { useState } from 'react';
import { ManuscriptEditForm } from './ManuscriptEditForm';
import { getAuthToken } from '../profile/utils';
import { ContentTitle } from '../layout/ContentTitle';
import { cachedRedactions, cachedRepositories } from './pages';
import { ManuscriptTabs } from './ManuscriptTabs';


export const ManuscriptPage = () => {
  const manuscript = useRouteLoaderData(
    'ManuscriptPage'
  ) as WithImages<WithPages<Manuscript>>;
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const authToken = getAuthToken()!;
  const location = useLocation();
  const pageNumber = manuscript.pages.length === 0 ? null : (location.state?.pageNumber || 1);
  const imageNumber = manuscript.images.length === 0 ? null : (location.state?.imageNumber || 1);
  const handleSave = async (value: Omit<ManuscriptBase, 'id'>) => {
    try {
      await api.updateManuscript(authToken, manuscript.id, value);
    } catch (err) {
      messageApi.error('Ошибка при отправке данных на сервер');
      return;
    }
    navigate('.', { replace: true });
    setMode('view');
  };
  const contentTitle =
    mode === 'view'
      ? [
          manuscript.repository.shortName,
          manuscript.collection.name,
          manuscript.cipher,
        ].join(', ')
      : 'Редактирование';
  return (
    <PageTitle
      title={mode === 'view' ? getFullCipher(manuscript) : 'Редактирование'}
    >
      {contextHolder}
      <Panel>
        <ContentTitle style={{marginBottom: '20px'}}>{contentTitle}</ContentTitle>
      </Panel>
      <Panel>
        {mode === 'view' && (
          <ManuscriptContext.Provider value={{manuscript, switchToEditMode: () => setMode('edit')}}>
            <ManuscriptTabs
              type='manuscript'
              imageNumber={imageNumber}
              pageNumber={pageNumber}
              manuscriptId={manuscript.id}
            />
          </ManuscriptContext.Provider>
        )}
        {mode === 'edit' && (
          <ManuscriptEditForm
            initialValue={manuscript}
            repositories={cachedRepositories}
            redactions={cachedRedactions}
            onCancel={() => setMode('view')}
            onSave={handleSave}
          />
        )}
      </Panel>
    </PageTitle>
  );
};
