import {API_ROOT, IMAGES_ROOT, VISUALIZATIONS_ROOT} from '../constants';
import {ApiError} from '../layout/errors';
import type {
  ManuscriptBase,
  Redaction,
  Collection,
  Image,
  ImageProcResult,
  Repository,
  Page,
} from './types';


export const fetchRedactions = async (
  authToken: string
): Promise<Redaction[]> => {
  const response = await fetch(`${API_ROOT}/redactions`, {
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (!response.ok) {
    throw new ApiError('redactions fetching failed', response);
  }
  return await response.json();
};

export const fetchRepositories = async (
  authToken: string
): Promise<Repository[]> => {
  const response = await fetch(`${API_ROOT}/repositories`, {
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (!response.ok) {
    throw new ApiError('repositories fetching failed', response);
  }
  return await response.json();
};

export const fetchCollections = async (): Promise<Collection[]> => {
  const response = await fetch(`${API_ROOT}/collections`);
  if (!response.ok) {
    throw new ApiError('collections fetching failed', response);
  }
  return await response.json();
};

export const fetchManuscripts = async (authToken: string): Promise<ManuscriptBase[]> => {
  const response = await fetch(`${API_ROOT}/manuscripts`, {
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (!response.ok) {
    throw new ApiError('manuscripts fetching failed', response);
  }
  const data = await response.json();
  return data.map((item: any) => ({
    ...item,
    createdAt: new Date(item.createdAt),
  }));
};

export const fetchManuscript = async (
  authToken: string, id: string
): Promise<ManuscriptBase> => {
  const response = await fetch(`${API_ROOT}/manuscripts/${id}`, {
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (!response.ok) {
    throw new ApiError('manuscript fetching failed', response);
  }
  const data = await response.json();
  return {
    ...data,
    createdAt: new Date(data.createdAt),
  };
};

export const fetchImages = async (
  authToken: string, manucriptId: string,
): Promise<Image[]> => {
  const response = await fetch(`${API_ROOT}/manuscripts/${manucriptId}/images`, {
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (!response.ok) {
    throw new ApiError('manuscript fetching failed', response);
  }
  return await response.json();
};

export const fetchPages = async (
  authToken: string, manucriptId: string,
): Promise<Page[]> => {
  const response = await fetch(`${API_ROOT}/manuscripts/${manucriptId}/pages`, {
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  });
  if (!response.ok) {
    throw new ApiError('manuscript fetching failed', response);
  }
  return await response.json();
};

export const createManuscript = async (
  authToken: string, manuscript: Omit<ManuscriptBase, 'id'>
): Promise<ManuscriptBase> => {
  const response = await fetch(`${API_ROOT}/manuscripts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(manuscript),
  });
  if (!response.ok) {
    throw new ApiError('manuscript creation failed', response);
  }
  const data = await response.json();
  return {
    ...data,
    createdAt: new Date(data.createdAt),
  };
};

export const updateManuscript = async (
  authToken: string,
  id: string | number,
  manuscript: Omit<ManuscriptBase, 'id'>
) => {
  const response = await fetch(`${API_ROOT}/manuscripts/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    body: JSON.stringify(manuscript),
  });
  if (!response.ok) {
    throw new ApiError('manuscript update failed', response);
  }
};

export const uploadFiles = async (
  authToken: string,
  manuscriptId: ManuscriptBase['id'],
  files: File[]
) => {
  const data = new FormData();
  files.forEach((file) => data.append('files', file));
  const response = await fetch(`${API_ROOT}/manuscripts/${manuscriptId}`, {
    method: 'POST',
    headers: {
      // если в body передаётся FormData с файлами,
      // то Content-Type будет установлен правильно multipart/form-data
      Authorization: authToken,
    },
    body: data,
  });
  if (!response.ok) {
    throw new ApiError('files uploading failed', response);
  }
};

type ImageWithSnippets = Image & {
  pageNumber: number;
  snippets: string[];
}

type ManuscriptWithSnippets = ManuscriptBase & {
  images: ImageWithSnippets[];
}

export type PaginatedSnippets = {
  manuscripts: ManuscriptWithSnippets[];
  pageSize: number;
  total: number;
  page: number;
};

export type SearchFields = {
  query: string;
  firstCentury?: number;
  lastCentury?: number;
  excludeDateRangeUnknown: boolean;
  months: number[];
  manuscripts?: string[];
  searchMode: string;
  page?: number;
};

export const searchSnippets = async (
  authToken: string,
  params: SearchFields,
): Promise<PaginatedSnippets> => {
  params.page = params.page ?? 1;
  const page = params.page;
  const response = await fetch(`${API_ROOT}/manuscripts/snippets`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  if (!response.ok) {
    throw new ApiError('records fetching failed', response);
  }
  let { manuscripts, total, pageSize } = await response.json();
  return {
    manuscripts,
    pageSize,
    total,
    page,
  };
};

export const sendImageXCut = async (
  authToken: string,
  image: Image,
  value: number | null,
) => {
  const response = await fetch(`${API_ROOT}/image/manual_cut_pages/${image.id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({value}),
  });
  if (!response.ok) {
    throw new ApiError('image update failed', response);
  }

};

export const startManuscriptXCutProcessing = async (
  authToken: string,
  manuscriptId: string,
) => {
  const response = await fetch(`${API_ROOT}/manuscripts/${manuscriptId}/images/cut-search-request`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new ApiError('manuscript processing failed', response);
  }
};

export const startManuscriptPagesCutProcessing = async (
  authToken: string,
  manuscriptId: string,
) => {
  const response = await fetch(`${API_ROOT}/manuscripts/${manuscriptId}/images/cut-request`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new ApiError('manuscript processing failed', response);
  }
};

export const getPageUrl = (page: Page): string =>
  `${IMAGES_ROOT}/${page.filename}`;

export const getImageUrl = (image: Image): string =>
  `${IMAGES_ROOT}/${image.filename}`;

export const getVisualizationUrl = (image: S3Image): string =>
  `${VISUALIZATIONS_ROOT}/${image.bucketName}/${image.filename}`;

export type S3Image = {
  bucketName: string;
  filename: string;
}

type ImageProcRawResult = Page & {
  processingResult: {
    visualizations: {
      filename: string;
      bucketName: string;
      step: number;
    }[];
  }
}

export const getManuscriptProc = async (
  authToken: string,
  id: string
): Promise<number> => {
  const response = await fetch(`${API_ROOT}/manuscripts/${id}/get_splitted_pages_status`,{
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  })
  if(!response.ok){
    throw new ApiError('error', response)
  }
  const result: number = await response.json();
  return result;
}

export const getPageProc = async (
  authToken: string,
  id: string,
): Promise<ImageProcResult> => {
  const response = await fetch(`${API_ROOT}/pages/${id}`,{
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
    },
  })
  if(!response.ok){
    throw new ApiError('image processing upploading error', response)
  }
  const result: ImageProcRawResult = await response.json();
  if (!result.processingResult) {
    return { ...result, has_visualizations: false };
  }
  const { visualizations } = result.processingResult;
  if (visualizations.length === 0) {
    return { ...result, has_visualizations: false };
  }
  const binarization = visualizations.filter(({step}) => step === 0);
  const segmentation = visualizations.filter(({step}) => step === 1);
  const classification = visualizations.filter(({step}) => step === 2);
  return {
    binary: binarization[0],
    background: segmentation[1],
    segments: segmentation[0],
    classified: classification[0],
    ...result,
    has_visualizations: true,
  }
}

export const postImageProc = async (
  authToken: string,
  imageId: Image['id']
) =>{
  const response = await fetch(`${API_ROOT}/pages/start_processing/${imageId}`,{
    method: 'POST',
    headers: {
      Authorization: authToken,
    },
    body: String(imageId),
  })
  if(!response.ok){
    throw new ApiError('image processing load error', response)
  }
}


export const fetchPageText = async (
  authToken: string,
  pageId: string,
) => {
  const response = await fetch(`${API_ROOT}/pages/${pageId}/text`, {
    headers: {
      Authorization: authToken,
    },
  });
  if(!response.ok){
    throw new ApiError('text load error', response)
  }
  return response.text();
};


export const uploadPageText = async (
  authToken: string,
  pageId: string,
  hocr: string,
) => {
  const formData = new FormData();
  formData.append('text', hocr);
  const response = await fetch(`${API_ROOT}/pages/${pageId}/text`,{
    method: 'PUT',
    headers: {
      Authorization: authToken,
    },
    body: formData,
  })
  if(!response.ok){
    throw new ApiError('text upload error', response)
  }
};
