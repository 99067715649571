import { Descriptions, Divider } from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';
import {
  Manuscript,
  formatMap,
  handwritingTypeMap,
  materialMap,
  monthMap,
  musicalSignMap,
} from './types';
import { getHistoricDate } from './utils';

type ManuscriptViewProps = {
  manuscript: Manuscript;
};

const isUrlValid = (url: string) => {
  try {
    new URL(url);
  } catch (err) {
    return false;
  }
  return true;
};

export const ManuscriptView = ({ manuscript }: ManuscriptViewProps) => {
  const { collection, repository } = manuscript;

  let link: React.ReactNode = (
    <a href={manuscript.link} target="_blank" rel="noreferrer">
      Ссылка
    </a>
  );
  if (!manuscript.link) {
    link = 'Не указано';
  } else if (!isUrlValid(manuscript.link)) {
    link = manuscript.link;
  }

  const itemsBySections: DescriptionsItemType[][] = [
    [
      {
        key: 'months',
        label: manuscript.months.length === 1 ? 'Месяц' : 'Месяцы',
        children: manuscript.months
          .map((month) => monthMap.get(month))
          .join(', '),
      },
      {
        key: 'musicalSign',
        label: 'Музыкальные знаки',
        children: musicalSignMap.get(manuscript.musicalSign),
      },
      {
        key: 'historicDate',
        label: 'Датировка',
        children: getHistoricDate(manuscript),
      },
      {
        key: 'specialName',
        label: 'Специальное название',
        children: manuscript.specialName || 'Нет',
      },
    ],
    [
      {
        key: 'cipher',
        label: 'Шифр',
        children: manuscript.cipher,
      },
      {
        key: 'repository',
        label: 'Хранилище',
        children: repository.name,
      },
      {
        key: 'collection',
        label: 'Фонд, собрание, коллекция',
        children: collection.name,
      },
    ],
    [
      {
        key: 'redaction',
        label: 'Извод',
        children: manuscript.redaction.name,
      },
      {
        key: 'format',
        label: 'Формат',
        children: formatMap.get(manuscript.format)!,
      },
      {
        key: 'pagesCount',
        label: 'Количество листов',
        children: manuscript.pagesCount ?? 'Не указано',
      },
      {
        key: 'pagesStateDescription',
        label: 'Сохранность текста',
        children: manuscript.pagesStateDescription || 'Не указано',
      },
    ],
    [
      {
        key: 'material',
        label: 'Материал',
        children: materialMap.get(manuscript.material),
      },
      {
        key: 'handwritingType',
        label: 'Тип почерка',
        children: handwritingTypeMap.get(manuscript.handwritingType),
      },
      {
        key: 'numberOfHandwritings',
        label: 'Количество почерков',
        children: manuscript.numberOfHandwritings ?? 'Не указано',
      },
      {
        key: 'handwritingRegionsDescription',
        label: 'Границы почерков',
        children: manuscript.handwritingRegionsDescription || 'Не указано',
      },
    ],
    [
      {
        key: 'link',
        label: 'Описание на сайте хранилища',
        children: link,
      },
      {
        key: 'createdAt',
        label: 'Дата добавления',
        children: manuscript.createdAt.toLocaleDateString('ru'),
      },
    ],
  ];
  return (
    <div>
      {itemsBySections.map((section, index) => (
        <div key={index}>
          <Descriptions
            colon={false}
            layout="vertical"
            items={section}
            labelStyle={{ marginTop: 15 }}
          />
          <Divider style={{ margin: '10px 0 20px' }} />
        </div>
      ))}
    </div>
  );
};
