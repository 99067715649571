import { Button, ConfigProvider, Input, type InputProps, type InputRef, Popover } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { TextAreaRef } from 'antd/es/input/TextArea';
import React, {useEffect, useRef, useState} from 'react';

type Letter = {
  small: string;
  capital: string;
  combining?: string;
  combiningWithTitlo?: string;
  button: boolean;
}

const LETTER_A = {
  small: '\u0430', // CYRILLIC SMALL LETTER A
  capital: '\u0410', // CYRILLIC CAPITAL LETTER A
  combining: '\u2df6', // COMBINING CYRILLIC LETTER A
  combiningWithTitlo: '\uf4f6', // PRIVATE USE
  button: false,
};
const LETTER_B = {
  small: '\u0431', // CYRILLIC SMALL LETTER BE
  capital: '\u0411', // CYRILLIC CAPITAL LETTER BE
  combining: '\u2de0', // COMBINING CYRILLIC LETTER BE
  combiningWithTitlo: '\uf4e0', // PRIVATE USE
  button: false,
};

export const ALPHABET: Letter[] = [
  LETTER_A,
  LETTER_B,
  {
    small: '\u0432', // CYRILLIC SMALL LETTER VE
    capital: '\u0412', // CYRILLIC CAPITAL LETTER VE
    combining: '\u2de1', // COMBINING CYRILLIC LETTER BE
    combiningWithTitlo: '\uf4e1', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0433', // CYRILLIC SMALL LETTER GHE
    capital: '\u0413', // CYRILLIC CAPITAL LETTER GHE
    combining: '\u2de2', // COMBINING CYRILLIC LETTER GHE
    combiningWithTitlo: '\uf4e2', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0434', // CYRILLIC SMALL LETTER DE
    capital: '\u0414', // CYRILLIC CAPITAL LETTER DE
    combining: '\u2de3', // COMBINING CYRILLIC LETTER DE
    combiningWithTitlo: '\uf4e3', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0511', // CYRILLIC SMALL LETTER REVERSED ZE
    capital: '\u0510', // CYRILLIC CAPITAL LETTER REVERSED ZE
    button: true,
  },
  {
    small: '\u0454', // CYRILLIC SMALL LETTER UKRAINIAN IE
    capital: '\u0404', // CYRILLIC CAPITAL LETTER UKRAINIAN IE
    combining: '\u2df7', // COMBINING CYRILLIC LETTER UKRAINIAN IE
    combiningWithTitlo: '\uf4f7', // PRIVATE USE
    button: true,
  },
  {
    small: '\u0455', // CYRILLIC SMALL LETTER DZE
    capital: '\u0405', // CYRILLIC CAPITAL LETTER DZE
    button: true,
  },
  {
    small: '\ua645', // CYRILLIC SMALL LETTER REVERSED DZE
    capital: '\ua644', // CYRILLIC CAPITAL LETTER REVERSED DZE
    button: true,
  },
  {
    small: '\u0436', // CYRILLIC SMALL LETTER ZHE
    capital: '\u0416', // CYRILLIC CAPITAL LETTER ZHE
    combining: '\u2de4',  // COMBINING CYRILLIC LETTER ZHE
    combiningWithTitlo: '\uf4e4',
    button: false,
  },
  {
    small: '\u0437', // CYRILLIC SMALL LETTER ZE
    capital: '\u0417', // CYRILLIC CAPITAL LETTER ZE
    combining: '\u2de5', // COMBINING CYRILLIC LETTER ZE
    combiningWithTitlo: '\uf4e5', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0438', // CYRILLIC SMALL LETTER I
    capital: '\u0418', // CYRILLIC CAPITAL LETTER I
    combining: '\ua675', // COMBINING CYRILLIC LETTER I
    combiningWithTitlo: '\uf675', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0456', // CYRILLIC SMALL LETTER BYELORUSSIAN-UKRAINIAN I
    capital: '\u0406', // CYRILLIC CAPITAL LETTER BYELORUSSIAN-UKRAINIAN I
    // combining: '\u{1e08f}', // COMBINING CYRILLIC SMALL LETTER BYELORUSSIAN-UKRAINIAN I
    button: true,
  },
  {
    small: '\u0457', // CYRILLIC SMALL LETTER YI
    capital: '\u0407', // CYRILLIC CAPITAL LETTER YI
    combining: '\ua676', // COMBINING CYRILLIC LETTER YI
    combiningWithTitlo: '\uf676', // PRIVATE USE
    button: true,
  },
  {
    small: '\u043a', // CYRILLIC SMALL LETTER KA
    capital: '\u041a', // CYRILLIC CAPITAL LETTER KA
    combining: '\u2de6', // COMBINING CYRILLIC LETTER KA
    combiningWithTitlo: '\uf4e6', // PRIVATE USE
    button: false,
  },
  {
    small: '\u043b', // CYRILLIC SMALL LETTER EL
    capital: '\u041b', // CYRILLIC CAPITAL LETTER EL
    combining: '\u2de7',
    combiningWithTitlo: '\uf4e7',
    button: false,
  },
  {
    small: '\u043c', // CYRILLIC SMALL LETTER EM
    capital: '\u041c', // CYRILLIC CAPITAL LETTER EM
    combining: '\u2de8',
    combiningWithTitlo: '\uf4e8',
    button: false,
  },
  {
    small: '\u043d', // CYRILLIC SMALL LETTER EN
    capital: '\u041d', // CYRILLIC CAPITAL LETTER EN
    combining: '\u2de9',
    combiningWithTitlo: '\uf4e9', // PRIVATE USE
    button: false,
  },
  {
    small: '\u043e', // CYRILLIC SMALL LETTER O
    capital: '\u041e', // CYRILLIC CAPITAL LETTER O
    combining: '\u2dea', // COMBINING CYRILLIC LETTER O
    combiningWithTitlo: '\uf4ea', // PRIVATE USE
    button: false,
  },
  {
    small: '\u047b', // CYRILLIC SMALL LETTER ROUND OMEGA
    capital: '\u047a', // CYRILLIC CAPITAL LETTER ROUND OMEGA
    button: true,
  },
  {
    small: '\u043f', // CYRILLIC SMALL LETTER PE
    capital: '\u041f', // CYRILLIC CAPITAL LETTER PE
    combining: '\u2deb', // COMBINING CYRILLIC LETTER PE
    combiningWithTitlo: '\uf4eb', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0440', // CYRILLIC SMALL LETTER ER
    capital: '\u0420', // CYRILLIC CAPITAL LETTER ER
    combining: '\u2dec', // COMBINING CYRILLIC LETTER ER
    combiningWithTitlo: '\uf4ec', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0441', // CYRILLIC SMALL LETTER ES
    capital: '\u0421', // CYRILLIC CAPITAL LETTER ES
    combining: '\u2ded', // COMBINING CYRILLIC LETTER ES
    combiningWithTitlo: '\uf4ed', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0442', // CYRILLIC SMALL LETTER TE
    capital: '\u0422', // CYRILLIC CAPITAL LETTER TE
    combining: '\u2dee', // COMBINING CYRILLIC LETTER TE
    combiningWithTitlo: '\uf4ee', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0479', // CYRILLIC SMALL LETTER UK
    capital: '\u0478', // CYRILLIC CAPITAL LETTER UK
    button: true,
  },
  {
    small: '\ua64b', // CYRILLIC SMALL LETTER MONOGRAPH UK
    capital: '\ua64a', // CYRILLIC CAPITAL LETTER MONOGRAPH UK
    combining: '\u2df9', // COMBINING CYRILLIC LETTER MONOGRAPH UK
    combiningWithTitlo: '\uf4f9', // PRIVATE USE
    button: true,
  },
  {
    small: '\u0443', // CYRILLIC SMALL LETTER U
    capital: '\u0423', // CYRILLIC CAPITAL LETTER U
    combining: '\ua677', // COMBINING CYRILLIC LETTER U
    combiningWithTitlo: '\uf677', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0444', // CYRILLIC SMALL LETTER EF
    capital: '\u0424', // CYRILLIC CAPITAL LETTER EF
    combining: '\ua69e', // COMBINING CYRILLIC LETTER EF
    combiningWithTitlo: '\uf69e', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0445', // CYRILLIC SMALL LETTER HA
    capital: '\u0425', // CYRILLIC CAPITAL LETTER HA
    combining: '\u2def', // COMBINING CYRILLIC LETTER HA
    combiningWithTitlo: '\uf4ef', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0461', // CYRILLIC SMALL LETTER OMEGA
    capital: '\u0460', // CYRILLIC CAPITAL LETTER OMEGA
    combining: '\ua67b', // COMBINING CYRILLIC LETTER OMEGA
    combiningWithTitlo: '\uf67b', // PRIVATE USE
    button: true,
  },
  {
    small: '\u047f', // CYRILLIC SMALL LETTER OT
    capital: '\u047e', // CYRILLIC CAPITAL LETTER OT
    button: true,
  },
  {
    small: '\u0446', // CYRILLIC SMALL LETTER TSE
    capital: '\u0426', // CYRILLIC CAPITAL LETTER TSE
    combining: '\u2df0', // COMBINING CYRILLIC LETTER TSE
    combiningWithTitlo: '\uf4f0', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0447', // CYRILLIC SMALL LETTER CHE
    capital: '\u0427', // CYRILLIC CAPITAL LETTER CHE
    combining: '\u2df1', // COMBINING CYRILLIC LETTER CHE
    combiningWithTitlo: '\uf4f1', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0448', // CYRILLIC SMALL LETTER SHA
    capital: '\u0428', // CYRILLIC CAPITAL LETTER SHA
    combining: '\u2df2', // COMBINING CYRILLIC LETTER SHA
    combiningWithTitlo: '\uf4f2', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0449', // CYRILLIC SMALL LETTER SHCHA
    capital: '\u0429', // CYRILLIC CAPITAL LETTER SHCHA
    combining: '\u2df3', // COMBINING CYRILLIC LETTER SHCHA
    combiningWithTitlo: '\uf4f3', // PRIVATE USE
    button: false,
  },
  {
    small: '\u044a', // CYRILLIC SMALL LETTER HARD SIGN
    capital: '\u042a', // CYRILLIC CAPITAL LETTER HARD SIGN
    combining: '\ua678', // COMBINING CYRILLIC LETTER HARD SIGN
    combiningWithTitlo: '\uf678', // PRIVATE USE
    button: false,
  },
  {
    small: '\u044b', // CYRILLIC SMALL LETTER YERU
    capital: '\u042b', // CYRILLIC CAPITAL LETTER YERU
    combining: '\ua679', // COMBINING CYRILLIC LETTER YERU
    combiningWithTitlo: '\uf679', // PRIVATE USE
    button: false,
  },
  {
    small: '\u044c', // CYRILLIC SMALL LETTER SOFT SIGN
    capital: '\u042c', // CYRILLIC CAPITAL LETTER SOFT SIGN
    combining: '\ua67a', // COMBINING CYRILLIC LETTER SOFT SIGN
    combiningWithTitlo: '\uf67a', // PRIVATE USE
    button: false,
  },
  {
    small: '\u0463', // CYRILLIC SMALL LETTER YAT
    capital: '\u0462', // CYRILLIC CAPITAL LETTER YAT
    combining: '\u2dfa', // COMBINING CYRILLIC LETTER YAT
    combiningWithTitlo: '\uf4fa', // PRIVATE USE
    button: true,
  },
  {
    small: '\u044e', // CYRILLIC SMALL LETTER YU
    capital: '\u042e', // CYRILLIC CAPITAL LETTER YU
    combining: '\u2dfb', // COMBINING CYRILLIC LETTER YU
    combiningWithTitlo: '\uf4fb', // PRIVATE USE
    button: false,
  },
  {
    small: '\ua657', // CYRILLIC SMALL LETTER IOTIFIED A
    capital: '\ua656', // CYRILLIC CAPITAL LETTER IOTIFIED A
    combining: '\u2dfc', // COMBINING CYRILLIC LETTER IOTIFIED A
    combiningWithTitlo: '\uf4fc', // PRIVATE USE
    button: true,
  },
  {
    small: '\u0465', // CYRILLIC SMALL LETTER IOTIFIED E
    capital: '\u0464', // CYRILLIC CAPITAL LETTER IOTIFIED E
    combining: '\ua69f', // COMBINING CYRILLIC LETTER IOTIFIED E
    combiningWithTitlo: '\uf69f', // PRIVATE USE
    button: true,
  },
  {
    small: '\u0467', // CYRILLIC SMALL LETTER LITTLE YUS
    capital: '\u0466', // CYRILLIC CAPITAL LETTER LITTLE YUS
    combining: '\u2dfd', // COMBINING CYRILLIC LETTER LITTLE YUS
    combiningWithTitlo: '\uf4fd', // PRIVATE USE
    button: true,
  },
  {
    small: '\u046b', // CYRILLIC SMALL LETTER BIG YUS
    capital: '\u046a', // CYRILLIC CAPITAL LETTER BIG YUS
    combining: '\u2dfe', // COMBINING CYRILLIC LETTER BIG YUS
    combiningWithTitlo: '\uf4fe', // PRIVATE USE
    button: true,
  },
  {
    small: '\u0469', // CYRILLIC SMALL LETTER IOTIFIED LITTLE YUS
    capital: '\u0468', // CYRILLIC CAPITAL LETTER IOTIFIED LITTLE YUS
    button: true,
  },
  {
    small: '\u046d', // CYRILLIC SMALL LETTER IOTIFIED BIG YUS
    capital: '\u046c', // CYRILLIC CAPITAL LETTER IOTIFIED BIG YUS
    combining: '\u2dff', // COMBINING CYRILLIC LETTER IOTIFIED BIG YUS
    combiningWithTitlo: '\uf4ff', // PRIVATE USE
    button: true,
  },
  {
    small: '\ua653', // CYRILLIC SMALL LETTER IOTIFIED YAT
    capital: '\ua652', // CYRILLIC CAPITAL LETTER IOTIFIED YAT
    button: true,
  },
  {
    small: '\u046f', // CYRILLIC SMALL LETTER KSI
    capital: '\u046e', // CYRILLIC CAPITAL LETTER KSI
    button: true,
  },
  {
    small: '\u0471', // CYRILLIC SMALL LETTER PSI
    capital: '\u0470', // CYRILLIC CAPITAL LETTER PSI
    button: true,
  },
  {
    small: '\u0473', // CYRILLIC SMALL LETTER FITA
    capital: '\u0472', // CYRILLIC CAPITAL LETTER FITA
    combining: '\u2df4', // COMBINING CYRILLIC LETTER FITA
    combiningWithTitlo: '\uf4f4', // PRIVATE USE
    button: true,
  },
  {
    small: '\u0475', // CYRILLIC SMALL LETTER IZHITSA
    capital: '\u0474', // CYRILLIC CAPITAL LETTER IZHITSA
    button: true,
  },
  {
    small: '\u0481', // CYRILLIC SMALL LETTER KOPPA
    capital: '\u04810', // CYRILLIC CAPITAL LETTER KOPPA
    button: true,
  },
];

const TITLO = {
    combining: '\u0483', // COMBINING CYRILLIC TITLO
  } as const;

const DIACRITICS = [
  TITLO,
  {
    combining: '\u0300',
  }, // COMBINING GRAVE ACCENT
  {
    combining: '\u030f',
  }, // COMBINING DOUBLE GRAVE ACCENT
  {
    combining: '\u0307',
  }, // COMBINING DOT ABOVE
  {
    combining: '\u0308',
  }, // COMBINING DIAERESIS
  {
    combining: '\u0301',
  }, // COMBINING ACUTE ACCENT
  {
    combining: '\u030b',
  }, // COMBINING DOUBLE ACUTE ACCENT
  {
    combining: '\u0486',
  }, // COMBINING CYRILLIC PSILI PNEUMATA
  {
    combining: '\u0485',
  }, // COMBINING CYRILLIC DASIA PNEUMATA
  {
    combining: '\ua67c',
  }, // COMBINING CYRILLIC KAVYKA
  {
    combining: '\u0302',
  }, // COMBINING CIRCUMFLEX ACCENT
  {
    combining: '\ua66f', // COMBINING CYRILLIC VZMET
  },
  {
    combining: '\u0487', // COMBINING CYRILLIC POKRYTIE
  },
  {
    combining: '\ua67d', // COMBINING CYRILLIC PAYEROK
  },
] as const;

const PUNCTUATION = [
  {
    character: '.', // FULL STOP
    button: false,
  },
  {
    character: ':', // COLON
    button: false,
  },
  {
    character: '\u205d', // TRICOLON
    button: true,
  },
  {
    character: '\u2056', // THREE DOT PUNCTUATION
    button: true,
  },
  {
    character: '\u2058', // FOUR DOT PUNCTUATION
    button: true,
  },
  {
    character: '\u2e2c', // SQUARED FOUR DOT PUNCTUATION
    button: true,
  },
  {
    character: '\u2020', // DAGGER
    button: true,
  },
  {
    character: '\u2021', // DOUBLE DAGGER
    button: true,
  },
  {
    character: '\u0482', // CYRILLIC THOUSANDS SIGN
    button: true,
  },
] as const;

const mappings = {
  '\u0073': '\u0455', // LATIN SMALL LETTER S: CYRILLIC SMALL LETTER DZE
  '\u0061': '\u0430', // LATIN SMALL LETTER A: CYRILLIC SMALL LETTER A
  '\u0065': '\u0454', // LATIN SMALL LETTER E: CYRILLIC SMALL LETTER UKRAINIAN IE
  '\u0131': '\u0456', // LATIN SMALL LETTER DOTLESS I: CYRILLIC SMALL LETTER BYELORUSSIAN-UKRAINIAN I
  '\u0060': '\u0300', // GRAVE ACCENT: COMBINING GRAVE ACCENT
};

const filterText = (text: string) => {
  for (const [key, value] of Object.entries(mappings)) {
    text = text.replace(key, value);
  }
  let startIndex = 0;
  for (; startIndex < text.length; startIndex += 1) {
    const isSign = DIACRITICS.find(({combining}) => combining === text[startIndex]);
    const isCombining = ALPHABET.find(({combining, combiningWithTitlo}) =>
      combining === text[startIndex] || combiningWithTitlo === text[startIndex]);
    if (!isSign && !isCombining) {
      break;
    }
  }
  return text.substring(startIndex);
}

type UseInsertCharacterProps = {
  value?: string;
  onChange?: (value: string) => void;
  getElement: () => HTMLInputElement | HTMLTextAreaElement | null;
};

const useInsertCharacter = ({value, onChange, getElement}: UseInsertCharacterProps) => {
  // cursorPositionRef и useEffect - костыль,
  // чтобы позиция курсора не смещалась в конец поля ввода
  // https://github.com/facebook/react/issues/955
  const cursorPositionRef = useRef<number|null>(null);
  useEffect(() => {
    const element = getElement();
    if (cursorPositionRef.current != null && element) {
      element.selectionStart = cursorPositionRef.current;
      element.selectionEnd = cursorPositionRef.current;
      cursorPositionRef.current = null;
    }
  }, [value]);
  const insertCharacter = (character: string) => {
    if (!value) {
      onChange?.(character);
    } else {
      const element = getElement();
      const start = element?.selectionStart ?? value.length;
      const end = element?.selectionEnd ?? value.length;
      cursorPositionRef.current = start + 1;
      onChange?.(value.substring(0, start) + character + value.substring(end));
    }
  };
  return insertCharacter;
};

export type SlavicInputProps = {
  enableMode?: boolean;
  value?: string;
  onChange?: (value: string) => void;
} & Omit<InputProps, 'value'|'onChange'>;

export const SlavicInput = ({value, onChange, enableMode = false, ...props}: SlavicInputProps) => {
  const [mode, setMode] = useState<'small'|'combining'|'combiningWithTitlo'>('small');
  const inputRef = useRef<InputRef>(null);
  const fontFamily = props?.style?.fontFamily;
  const insertCharacter = useInsertCharacter({value, onChange, getElement: () => inputRef.current?.input ?? null});
  const letterButtons = ALPHABET.filter(({button}) => button).map((letter) => (
    <Button
      key={letter.small}
      disabled={letter[mode] == null}
      style={{ fontFamily }}
      onClick={() => {
        insertCharacter(letter[mode] || letter.small);
        setMode('small');
      }}
      onMouseDown={e => e.preventDefault()}
    >
      {letter.small}
    </Button>
  ));
  const diacriticsButtons = DIACRITICS.map(({combining}) => (
    <Button
      key={combining}
      style={{ fontFamily, backgroundColor: '#ffb' }}
      onClick={() => insertCharacter(combining)}
      onMouseDown={e => e.preventDefault()}
    >
      {LETTER_A.small}{combining}
    </Button>
  ));
  const modeButtons = (
    <>
      <Button
        style={{ fontFamily }}
        disabled={mode === 'small'}
        onClick={() => setMode('small')}
        onMouseDown={e => e.preventDefault()}
      >
        {LETTER_A.small}{LETTER_B.small}
      </Button>
      <Button
        style={{ fontFamily }}
        disabled={mode === 'combining'}
        onClick={() => setMode('combining')}
        onMouseDown={e => e.preventDefault()}
      >
        {LETTER_A.small}{LETTER_B.combining}
      </Button>
      <Button
        style={{ fontFamily }}
        disabled={mode === 'combiningWithTitlo'}
        onClick={() => setMode('combiningWithTitlo')}
        onMouseDown={e => e.preventDefault()}
      >
        {LETTER_A.small}{LETTER_B.combiningWithTitlo}
      </Button>
    </>
  );
  const content = (
    <div style={{display: 'flex'}}>
      {enableMode && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRight: '1px solid #ccc',
            marginRight: 5,
            paddingRight: 5,
          }}
        >
          {modeButtons}
        </div>
      )}
      <div
        style={{
          maxWidth: 300,
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
        }}
      >
        {letterButtons}
        {diacriticsButtons}
      </div>
    </div>
  );
  const handleChange: InputProps['onChange'] = (event) => {
    let text = event.target.value;
    text = filterText(text);
    onChange?.(text);
  }
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (mode != 'small') {
      for (const letter of ALPHABET) {
        if (letter.small === event.key) {
          const character = letter[mode];
          if (character != null) {
            event.preventDefault();
            insertCharacter(character);
            setMode('small');
            return;
          }
        }
      }
    }
  }
  return (
    <Popover content={content} placement="bottom" trigger="focus">
      <Input
        ref={inputRef}
        value={value}
        style={{ fontFamily }}
        spellCheck={false}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </Popover>
  );
};

type SlavicTextAreaProps = {
  value?: string;
  onChange?: (value: string) => void;
} & Omit<TextAreaProps, 'value'|'onChange'>;

export const SlavicTextArea = ({value, onChange, ...props}: SlavicTextAreaProps) => {
  const [mode, setMode] = useState<'small'|'capital'|'combining'|'combiningWithTitlo'>('small');
  const textareaRef = useRef<TextAreaRef>(null);
  const fontFamily = props?.style?.fontFamily;
  const insertCharacter = useInsertCharacter({
    value,
     onChange,
    getElement: () => textareaRef.current?.resizableTextArea?.textArea ?? null,
  });
  const handleChange: TextAreaProps['onChange'] = (event) => {
    let text = event.target.value;
    text = filterText(text);
    onChange?.(text);
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (mode != 'small') {
      for (const letter of ALPHABET) {
        if (letter.small === event.key) {
          const character = letter[mode];
          if (character != null) {
            event.preventDefault();
            insertCharacter(character);
            setMode(mode => ['small', 'capital'].includes(mode) ? mode : 'small');
            return;
          }
        }
      }
    }
  }
  const letterButtons = ALPHABET.filter(({button}) => button).map((letter) => (
    <Button
      key={letter.small}
      disabled={letter[mode] == null}
      style={{ fontFamily }}
      onClick={() => {
        insertCharacter(letter[mode] || letter.small);
        setMode(mode => ['small', 'capital'].includes(mode) ? mode : 'small');
      }}
      onMouseDown={e => e.preventDefault()}
    >
      {mode === 'capital' ? letter.capital : letter.small}
    </Button>
  ));
  const diacriticsButtons = DIACRITICS.map(({combining}) => (
    <Button
      key={combining}
      style={{ fontFamily, backgroundColor: '#ffb' }}
      onClick={() => insertCharacter(combining)}
      onMouseDown={e => e.preventDefault()}
    >
      {LETTER_A.small}{combining}
    </Button>
  ));
  const punctuationButtons = PUNCTUATION.map(({character}) => (
    <Button
    key={character}
    style={{ fontFamily, backgroundColor: '#bbf' }}
    onClick={() => insertCharacter(character)}
    onMouseDown={e => e.preventDefault()}
    >
      {character}
    </Button>
  ));
  const modeButtons = (
    <>
      <Button
        style={{ fontFamily }}
        disabled={mode === 'small'}
        onClick={() => setMode('small')}
        onMouseDown={e => e.preventDefault()}
      >
        {LETTER_A.small}{LETTER_B.small}
      </Button>
      <Button
        style={{ fontFamily }}
        disabled={mode === 'capital'}
        onClick={() => setMode('capital')}
        onMouseDown={e => e.preventDefault()}
      >
        {LETTER_A.small}{LETTER_B.capital}
      </Button>
      <Button
        style={{ fontFamily }}
        disabled={mode === 'combining'}
        onClick={() => setMode('combining')}
        onMouseDown={e => e.preventDefault()}
      >
        {LETTER_A.small}{LETTER_B.combining}
      </Button>
      <Button
        style={{ fontFamily }}
        disabled={mode === 'combiningWithTitlo'}
        onClick={() => setMode('combiningWithTitlo')}
        onMouseDown={e => e.preventDefault()}
      >
        {LETTER_A.small}{LETTER_B.combiningWithTitlo}
      </Button>
    </>
  );
  const numbers: string[] = [];
  if (value != null) {
    let number = 1;
    for(const line of value?.split?.('\n')) {
      if (line.trim() === '') {
        numbers.push('\u00a0');
      } else {
        numbers.push(String(number));
        number++;
      }
    }
  }
  const result = (
    <div style={{display: 'flex'}}>
      <div style={{display: 'flex', flex: 1}}>
        <div style={{fontFamily, width: 20, paddingTop: 6, textAlign: 'center', userSelect: 'none'}}>
          {numbers.map(number => <div>{number}</div>)}
        </div>
        <Input.TextArea
          ref={textareaRef}
          value={value}
          spellCheck={false}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          {...props}
        />
      </div>
      <div style={{paddingLeft: 20, paddingBottom: 5}}>
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 30px)'}}>{modeButtons}</div>
        <hr />
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 30px)'}}>{letterButtons}</div>
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 30px)'}}>{diacriticsButtons}</div>
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 30px)'}}>{punctuationButtons}</div>
      </div>
    </div>
  );
  return (
    <ConfigProvider theme={{components: {Button: {paddingInline: 4}}}}>
      {result}
    </ConfigProvider>
  );
};
