import {
  Button,
  Divider,
  Pagination,
  message,
} from 'antd';
import { Panel } from '../layout/Panel';
import {
  Manuscript,
  ManuscriptBase,
  Redaction,
  RelationNotFoundError,
  Repository,
  WithImages,
  WithPages,
  WithPagesWithVyaz,
  enrichManuscript,
} from './types';
import { ManuscriptsTable } from './ManuscriptsTable';
import * as api from './api';
import * as vapi from '../vyaz/api';
import { SearchImagesForm } from './SearchImagesForm';
import { getFullCipher } from '../manuscripts/utils';
import {
  LoaderFunctionArgs,
  Navigate,
  redirect,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { PageTitle } from '../layout/PageTitle';
import { useState } from 'react';
import { ManuscriptEditForm } from './ManuscriptEditForm';
import { getAuthToken, buildLoginUrl } from '../profile/utils';
import { ContentTitle } from '../layout/ContentTitle';
import { Str2Tag } from '../layout/Str2Tag';
import { type SearchFields } from './api';
import { VyazBase } from '../vyaz/types';

export let cachedRepositories: Repository[] = [];
export let cachedRedactions: Redaction[] = [];
export let cachedManuscripts: Manuscript[] = [];

export const loadVyaz = async (args: LoaderFunctionArgs): Promise<Response | WithPagesWithVyaz<Manuscript>[]> => {
  const {request} = args;
  const authToken = getAuthToken();
  if (authToken == null) {
    return redirect(buildLoginUrl(request.url));
  }
  const manuscripts = await vapi.fetchManuscriptsWithVyaz(authToken);
  try {
    return manuscripts.map((m) =>
      enrichManuscript(m, cachedRepositories, cachedRedactions)
    );
  } catch (error) {
    if (!(error instanceof RelationNotFoundError)) {
      throw error;
    }
    // Promise.all для параллельного выполнения запросов
    [cachedRepositories, cachedRedactions] = await Promise.all([
      api.fetchRepositories(authToken),
      api.fetchRedactions(authToken),
    ]);
    return manuscripts.map((m) =>
      enrichManuscript(m, cachedRepositories, cachedRedactions)
    );
  }
};

export const loadVyazForPage = async({
  params,
  request,
}: LoaderFunctionArgs): Promise<Response | VyazBase | null> => {
  const authToken = getAuthToken();
  if (authToken == null) {
    return redirect(buildLoginUrl(request.url));
  }
  let {manuscriptId, pageNumber} = params;
  const pages = await api.fetchPages(authToken, manuscriptId!);
  if (pages.length >= Number(pageNumber!) && Number(pageNumber!) > 0) {
    return vapi.fetchVyazRec(authToken, pages[Number(pageNumber!) - 1].id);
  }
  return redirect(`/manuscripts/${manuscriptId}`);
};


export const loadManuscripts = async ({request}: LoaderFunctionArgs): Promise<Response | Manuscript[]> => {
  const authToken = getAuthToken();
  if (authToken == null) {
    return redirect(buildLoginUrl(request.url));
  }
  const baseManuscripts = await api.fetchManuscripts(authToken);
  try {
    return baseManuscripts.map((m) =>
      enrichManuscript(m, cachedRepositories, cachedRedactions)
    );
  } catch (error) {
    if (!(error instanceof RelationNotFoundError)) {
      throw error;
    }
    // Promise.all для параллельного выполнения запросов
    [cachedRepositories, cachedRedactions] = await Promise.all([
      api.fetchRepositories(authToken),
      api.fetchRedactions(authToken),
    ]);
    return baseManuscripts.map((m) =>
      enrichManuscript(m, cachedRepositories, cachedRedactions)
    );
  }
};

const ManuscriptCreateButton = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/manuscripts/new');
  };
  return (
    <Button type="primary" htmlType="button" onClick={handleClick}>
      Добавить
    </Button>
  );
};

const titleWithButtonStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const ManuscriptsPage = () => {
  const manuscripts = useLoaderData() as Manuscript[];
  return (
    <PageTitle title="Каталог рукописей">
      <Panel style={titleWithButtonStyle}>
        <div>
          <ContentTitle>Каталог рукописей</ContentTitle>
          <br />
          Общее количество рукописей {manuscripts.length}
          <br />
          Количество разрезанных рукописей {manuscripts.filter(m => m.splittedPagesStatus === 3).length}
        </div>
        <ManuscriptCreateButton />
      </Panel>
      <Panel>
        <ManuscriptsTable
          manuscripts={manuscripts}
          repositories={cachedRepositories}
        />
      </Panel>
    </PageTitle>
  );
};

export const loadManuscriptWithPagesAndImages = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<Response | WithImages<WithPages<Manuscript>>> => {
  const authToken = getAuthToken();
  if (authToken == null) {
    return redirect(buildLoginUrl(request.url));
  }
  // Promise.all для параллельного выполнения запросов
  const [base, pages, images] = await Promise.all([
    api.fetchManuscript(authToken, params.manuscriptId!),
    api.fetchPages(authToken, params.manuscriptId!),
    api.fetchImages(authToken, params.manuscriptId!),
  ]);
  try {
    const manuscript = enrichManuscript(
      base,
      cachedRepositories,
      cachedRedactions
    );
    return { ...manuscript, pages, images };
  } catch (error) {
    if (!(error instanceof RelationNotFoundError)) {
      throw error;
    }
    // Promise.all для параллельного выполнения запросо
    [cachedRepositories, cachedRedactions] = await Promise.all([
      api.fetchRepositories(authToken),
      api.fetchRedactions(authToken),
    ]);
    const manuscript = enrichManuscript(
      base,
      cachedRepositories,
      cachedRedactions
    );
    return { ...manuscript, pages, images };
  }
};

export const loadCachedDataForManuscripts = async ({request}: LoaderFunctionArgs) => {
  const authToken = getAuthToken();
  if (authToken == null) {
    return redirect(buildLoginUrl(request.url));
  }
  [cachedRepositories, cachedRedactions] = await Promise.all([
    api.fetchRepositories(authToken),
    api.fetchRedactions(authToken),
  ]);
  return null;
};

export const ManuscriptCreatePage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate('/manuscripts/');
  };
  const authToken = getAuthToken();
  if (authToken == null) {
    return <Navigate to={buildLoginUrl(window.location.href)} />;
  }
  const handleSave = async (value: Omit<ManuscriptBase, 'id'>) => {
    let manuscript: ManuscriptBase;
    try {
      manuscript = await api.createManuscript(authToken, value);
    } catch (err) {
      messageApi.error('Ошибка при отправке данных на сервер');
      return;
    }
    navigate(`/manuscripts/${manuscript.id}`);
  };
  return (
    <PageTitle title="Каталог рукописей">
      {contextHolder}
      <Panel>
        <ContentTitle>Добавить рукопись</ContentTitle>
      </Panel>
      <Panel>
        <ManuscriptEditForm
          repositories={cachedRepositories}
          redactions={cachedRedactions}
          onCancel={handleCancel}
          onSave={handleSave}
        />
      </Panel>
    </PageTitle>
  );
};

const Snippet = ({ snippet }: { snippet: string }) => {
  return (
    <div style={{ fontFamily: 'MonomakhUnicode', paddingLeft: 150 }}>
      <Str2Tag value={snippet} />
    </div>
  );
};

type SearchSnippetsResults = Omit<api.PaginatedSnippets, 'manuscripts'> & {
  manuscripts: (api.PaginatedSnippets['manuscripts'][number] & Manuscript)[];
};

export const SearchSnippetsPage = () => {
  const manuscripts = useLoaderData() as Manuscript[];
  const [messageApi, contextHolder] = message.useMessage();
  const [results, setResults] = useState<SearchSnippetsResults>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchFields, setSearchFields] = useState<SearchFields>();
  const authToken = getAuthToken();
  if (authToken == null) {
    return <Navigate to={buildLoginUrl(window.location.href)} />;
  }
  const changePage = (page: number) => {
    const newParams = {...searchFields!, page};
    handleSubmit(newParams);
  };
  const handleSubmit = async (values: SearchFields) => {
    let receivedResults: api.PaginatedSnippets;
    try {
      receivedResults = await api.searchSnippets(authToken, values);
      setSearchFields(values);
    } catch (err) {
      messageApi.error('Ошибка при отправке данных на сервер');
      return;
    }
    // добавление полей redaction, repository и collection
    let manuscripts: SearchSnippetsResults['manuscripts'];
    try {
      manuscripts = receivedResults.manuscripts.map(({ images, ...base }) => ({
        ...enrichManuscript(base, cachedRepositories, cachedRedactions),
        images,
      }));
    } catch (error) {
      if (!(error instanceof RelationNotFoundError)) {
        throw error;
      }
      // Promise.all для параллельного выполнения запросов
      [cachedRepositories, cachedRedactions] = await Promise.all([
        api.fetchRepositories(authToken),
        api.fetchRedactions(authToken),
      ]);
      manuscripts = receivedResults.manuscripts.map(({ images, ...base }) => ({
        ...enrichManuscript(base, cachedRepositories, cachedRedactions),
        images,
      }));
    }
    setResults({ ...receivedResults, manuscripts });
    setSearchParams((params) => {
      const result = new URLSearchParams(params);
      result.set("query", values.query);
      return result;
    });
  };
  let heading: string | null = null;
  if (results != null) {
    if (results.manuscripts.length === 0) {
      heading = 'Ничего не найдено';
    } else {
      heading = `Найдено результатов ${results.total}`;
    }
  }
  return (
    <PageTitle title="Поиск">
      <Panel>
        <div>
          <ContentTitle>Поиск</ContentTitle>
          <br />
          {heading}
        </div>
      </Panel>
      <Panel>
        {contextHolder}
        <SearchImagesForm
          fontFamily="MonomakhUnicode"
          onSubmit={handleSubmit}
          manuscripts={manuscripts}
          initialValue={{query: searchParams.get("query") || ''}}
        />
        <Divider />
        {results != null && results.total > results.pageSize && (
          <Pagination
            current={searchFields!.page ?? 1}
            pageSize={results.pageSize}
            total={results.total}
            onChange={changePage}
            showSizeChanger={false}
          />
        )}
        {results != null &&
          results.manuscripts.map(({ images, ...manuscript }) => (
            <div style={{ margin: '10px 0' }}>
              <div style={{ fontSize: '1.2em' }}>
                {getFullCipher(manuscript)}
              </div>
              <div>
                {images.map(({ pageNumber, snippets }) => (
                  <div style={{ margin: '5px 0' }}>
                    <a
                      href={`/manuscripts/${manuscript.id}/images/${pageNumber + 1}`}
                      target="_blank"
                    >
                      Страница {pageNumber + 1}
                    </a>
                    <div>
                      {snippets.map((snippet) => (
                        <Snippet key={snippet} snippet={snippet} />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </Panel>
    </PageTitle>
  );
};
