import React, { useState } from 'react';
import { Button, Form, Input, Select, SelectProps } from 'antd';
import CSS from 'csstype';
import type { MorphDictionaryRecord } from './types';
import {
  partOfSpeechOptions,
  participleTypeOptions,
  pronounTypeOptions,
  degreeOfComparisonOptions,
  grammaticalGenderOptions,
  statusOptions,
  verbRepresentationOptions,
  voiceOptions,
  tenseOptions,
  grammaticalCaseOptions,
  grammaticalNumberOptions,
  grammaticalPersonOptions,
  numeralTypeOptions,
  grammaticalMoodOptions,
  personalityCategoryOptions,
  completenessConcisenessCategoryOptions,
} from './types';
import { useNavigate } from 'react-router-dom';
import { SlavicInput } from './SlavicField';
import { serializeSearchParams } from '../utils';

type ItemProps = React.ComponentProps<
  typeof Form.Item<SearchParams>
> & { minWidth: number };

const Field = ({ children, style, minWidth, ...props }: ItemProps) => (
  <Form.Item<SearchParams>
    style={{ ...style, minWidth, flex: 1 }}
    {...props}
  >
    {children}
  </Form.Item>
);

type Option = {
  readonly value: number;
  readonly label: string;
};

type SelectSearchProps = Omit<
  SelectProps<number, Option>,
  'showSearch' | 'onSearch' | 'filterOption'
>;

const SelectSearch = ({ options, ...props }: SelectSearchProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const filter = ({ label }: Option) =>
    label.toLowerCase().includes(searchValue);
  const filteredOptions =
    searchValue && options ? options.filter(filter) : options;
  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue.toLowerCase());
  };
  return (
    <Select
      options={filteredOptions}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      {...props}
    />
  );
};

type CommonSelectProps = {
  placeholder: string;
  options: Option[];
};

type SelectFieldProps = Omit<ItemProps, 'children'> & CommonSelectProps;

const SelectField = ({ placeholder, options, ...props }: SelectFieldProps) => (
  <Field {...props}>
    <Select placeholder={placeholder} options={options} />
  </Field>
);

const wrapperStyle: CSS.Properties = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  columnGap: '16px',
  rowGap: '8px',
};

export const stringMatchingModes = [
  {value: 'icontains', label: "Содержит"},
  {value: "iexact", label: "Целиком"},
  {value: "istartswith", label: "Начинается"},
  {value: "iendswith", label: "Заканчивается"},
];

export type SearchParams = {
  page?: number;
  stringMatchingMode?: string; //'icontains' | 'iexact' | 'istartswith' | 'iendswith';
} & Partial<MorphDictionaryRecord>;

const stringParams = [
  'lemma',
  'word',
  'presentTenseForm',
  'stringMatchingMode',
  'comment',
  'serviceComment',
] as const;

const numberParams = [
  'status',
  'partOfSpeech',
  'verbRepresentation',
  'pronounType',
  'numeralType',
  'personalityCategory',
  'grammaticalMood',
  'participleType',
  'voice',
  'grammaticalPerson',
  'tense',
  'grammaticalCase',
  'completenessConcisenessCategory',
  'grammaticalGender',
  'degreeOfComparison',
  'grammaticalNumber',
  'page',
] as const;

export const parseSearchFormParams = (
  urlParams: URLSearchParams
): SearchParams => {
  const result: SearchParams = {};
  for (const key of stringParams) {
    if (urlParams.has(key)) {
      result[key] = urlParams.get(key)!;
    }
  }
  for (const key of numberParams) {
    if (urlParams.has(key)) {
      result[key] = Number(urlParams.get(key)!);
    }
  }
  return result;
};

export const MorphDictionarySearchForm = ({
  fontFamily,
}: {
  fontFamily: string;
}) => {
  const navigate = useNavigate();
  const searchDictionaryRecords = (params: SearchParams) => {
    const urlParams = serializeSearchParams(params);
    const url = `/morphology/dictionary/search?${urlParams}`;
    navigate(url);
  };
  return (
    <Form<SearchParams> layout="vertical" onFinish={searchDictionaryRecords}>
      <div style={wrapperStyle}>
        <Form.Item<SearchParams>
          label="Лемма"
          name="lemma"
        >
          <SlavicInput style={{ fontFamily, minWidth: 140 }} />
        </Form.Item>
        <Form.Item<SearchParams>
          label="Слово"
          name="word"
        >
          <SlavicInput style={{ fontFamily, minWidth: 140 }} enableMode />
        </Form.Item>
        <Field
          label="Сравнение"
          name="stringMatchingMode"
          minWidth={170}
        >
          <Select defaultValue="icontains" options={stringMatchingModes} />
        </Field>
        <Button type="primary" htmlType="submit" style={{ marginLeft: 'auto' }}>
          Поиск
        </Button>
      </div>
      <div>
        <div style={wrapperStyle}>
          <SelectField
            placeholder='Любой'
            label="Статус"
            name="status"
            options={statusOptions}
            minWidth={170}
          />
          <Field label="Часть речи" name="partOfSpeech" minWidth={170}>
            <SelectSearch
              allowClear
              placeholder="Любая"
              notFoundContent={null}
              options={partOfSpeechOptions}
            />
          </Field>
          <SelectField
            placeholder="Любая"
            label="Репрезентация глагола"
            name="verbRepresentation"
            options={verbRepresentationOptions}
            minWidth={170}
          />
        </div>
        <div style={wrapperStyle}>
          <SelectField
          placeholder="Любой"
          label="Разряд местоимения"
          name="pronounType"
          options={pronounTypeOptions}
          minWidth={170}
        />
          <SelectField
            placeholder="Любой"
            label="Разряд числительного"
            name="numeralType"
            options={numeralTypeOptions}
            minWidth={170}
          />
          <SelectField
            placeholder="Любой вариант"
            label="Личность / неличность"
            name="personalityCategory"
            options={personalityCategoryOptions}
            minWidth={170}
          />
        </div>
        <div style={wrapperStyle}>
          <SelectField
            placeholder="Любое"
            label="Наклонение глагола"
            name="grammaticalMood"
            options={grammaticalMoodOptions}
            minWidth={170}
          />
          <SelectField
            placeholder="Любой"
            label="Тип причастия"
            name="participleType"
            options={participleTypeOptions}
            minWidth={170}
          />
          <SelectField
            placeholder="Любой"
            label="Залог причастия"
            name="voice"
            options={voiceOptions}
            minWidth={170}
          />
        </div>
        <div style={wrapperStyle}>
          <SelectField
            placeholder="Любое"
            label="Лицо"
            name="grammaticalPerson"
            options={grammaticalPersonOptions}
            minWidth={170}
          />
          <SelectField
            placeholder="Любое"
            label="Время"
            name="tense"
            options={tenseOptions}
            minWidth={170}
          />
          <SelectField
            placeholder="Любой"
            label="Падеж"
            name="grammaticalCase"
            options={grammaticalCaseOptions}
            minWidth={170}
          />
        </div>
        <div style={wrapperStyle}>
          <SelectField
            placeholder="Любой вариант"
            label="Полнота / краткость"
            name="completenessConcisenessCategory"
            options={completenessConcisenessCategoryOptions}
            minWidth={170}
          />
          <SelectField
            placeholder="Любой"
            label="Род"
            name="grammaticalGender"
            options={grammaticalGenderOptions}
            minWidth={170}
          />
          <SelectField
            placeholder="Любая"
            label="Степень сравнения"
            name="degreeOfComparison"
            options={degreeOfComparisonOptions}
            minWidth={170}
          />
        </div>
        <div style={wrapperStyle}>
          <Form.Item<SearchParams>
            label="Форма настоящего времени"
            name="presentTenseForm"
          >
            <SlavicInput style={{ fontFamily, minWidth: 170 }} enableMode />
          </Form.Item>
          <SelectField
            placeholder="Любое"
            label="Число"
            name="grammaticalNumber"
            options={grammaticalNumberOptions}
            minWidth={170}
          />
        </div>
        <div style={wrapperStyle}>
          <Form.Item<SearchParams>
            label="Комментарий к лемме"
            name="comment"
          >
            <Input style={{ minWidth: 170 }} />
          </Form.Item>
          <Form.Item<SearchParams>
            label="Комментарий к словоформе"
            name="serviceComment"
          >
            <Input style={{ minWidth: 170 }} />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
