import React from "react";
import { Navigate, useLocation, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { Manuscript, WithImages, ImageContext, WithPages } from "./types";
import { PaginationWithInput } from "../layout/PaginationWithInput";
import { getFullCipher } from "./utils";
import { ManuscriptTabs } from "./ManuscriptTabs";
import { Panel } from "../layout/Panel";
import { PageTitle } from "../layout/PageTitle";
import { ContentTitle } from "../layout/ContentTitle";


export const ImagePage = () => {
  const manuscript = useRouteLoaderData(
    'ManuscriptPage'
  ) as WithImages<WithPages<Manuscript>>;
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const pageNumber = manuscript.pages.length === 0 ? null : (location.state?.pageNumber || 1);
  if (manuscript.images.length === 0) {
    return <Navigate to={`/manuscripts/${manuscript.id}`} />;
  }
  if (params?.imageNumber == null) {
    return <Navigate to={`/manuscripts/${manuscript.id}`} />;
  }
  const imageNumber = parseInt(params?.imageNumber);
  if (manuscript.images.length < imageNumber) {
    return <Navigate to={`/manuscripts/${manuscript.id}/images/1`} />;
  }
  if (
    !Number.isInteger(imageNumber) ||
    imageNumber <= 0 ||
    manuscript.images.length < imageNumber
  ) {
    return <Navigate to={`/manuscripts/${manuscript.id}`} />;
  }
  const switchToImage = (newPageNumber: number) => {
    navigate(`/manuscripts/${manuscript.id}/images/${newPageNumber}`);
  };
  const wrapper = (tab: React.ReactNode) => (
    <div style={{minHeight: '100vh'}}>
        <PaginationWithInput
          current={imageNumber}
          total={manuscript.images.length}
          onChange={switchToImage}
        />
        {tab}
      </div>
  );
  const title = getFullCipher(manuscript);
  const contentTitle = [
    manuscript.repository.shortName,
    manuscript.collection.name,
    manuscript.cipher,
  ].join(', ');
  const image = manuscript.images[imageNumber - 1];
  const {splittedPagesStatus} = manuscript;
  return (
    <PageTitle title={title}>
      <Panel>
        <ContentTitle style={{marginBottom: '20px'}}>{contentTitle}</ContentTitle>
      </Panel>
      <Panel>
        <ImageContext.Provider value={{image, manuscriptId: manuscript.id, splittedPagesStatus}}>
          <ManuscriptTabs
            key={imageNumber}
            type='image'
            manuscriptId={manuscript.id}
            imageNumber={imageNumber}
            pageNumber={pageNumber}
            wrapper={wrapper}
          />
        </ImageContext.Provider>
      </Panel>
    </PageTitle>
  );
};
