import { ConfigProvider, Layout } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import React from 'react';
import { Outlet } from 'react-router-dom';
import CSS from 'csstype';
import { SideBar } from './layout/SideBar';
import { useLocalStorage } from './utils';

const theme = {
  components: {
    Descriptions: {
      itemPaddingBottom: 0,
    },
    Layout: {
      headerBg: '#fff',
      bodyBg: '#f6f7fc',
    },
  },
};

const siderStyle: CSS.Properties = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  left: 0,
  top: 0,
  bottom: 0,
};

const useCollapsed = () => useLocalStorage('App/Sider/collapsed', 'false');

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useCollapsed();
  const mainStyle: CSS.Properties = {
    minHeight: '100vh',
    marginLeft: collapsed === 'true' ? '90px' : '250px',
    transitionDuration: '500ms',
  };
  return (
    <ConfigProvider locale={ruRU} input={{ autoComplete: 'off' }} theme={theme}>
      <Layout hasSider>
        <Layout.Sider
          width={250}
          collapsed={collapsed === 'true'}
          collapsedWidth={90}
          collapsible
          theme="light"
          style={siderStyle}
          onCollapse={(collapse) => setCollapsed(String(collapse))}
        >
          <SideBar collapsed={collapsed === 'true'} />
        </Layout.Sider>
        <Layout style={mainStyle}>
          <Layout.Content>
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
