import { Button, message } from "antd";
import { useContext } from "react";
import { ManuscriptView } from "../ManuscriptView";
import { useProcessManuscript } from "../useProcessManuscript";
import { ManuscriptContext, SplittedPagesStatus } from "../types";
import { getAuthToken } from "../../profile/utils";
import * as api from '../api';

export const ManuscriptMainTab = () => {
  const authToken = getAuthToken()!;
  const drawCuts = () => {
    api.startManuscriptXCutProcessing(authToken, manuscript.id);
    messageApi.info({ content: "Можно начинать ручную валидацию разрезов", duration: 10 });
  }
  const [messageApi, contextHolder] = message.useMessage();
  const {manuscript, switchToEditMode} = useContext(ManuscriptContext)!;
  const { manuscriptProcResult, process } = useProcessManuscript(manuscript, authToken, messageApi);
  return (
    <>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          gap: '15px',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Button type="primary" htmlType="button" onClick={switchToEditMode}>
          Редактировать
        </Button>
        {manuscriptProcResult < 2 && (
          <>
            <Button style={{marginTop: 15, display: 'block'}} type="primary" onClick={drawCuts}>
              Предложить разрезы
            </Button>
          </>
        )}
        {manuscriptProcResult < 3 && (
          <>
            <Button style={{marginTop: 15, display: 'block'}} type="primary" onClick={process} disabled={manuscriptProcResult === SplittedPagesStatus.PROCESSING}>
              {[SplittedPagesStatus.CUT_EXIST, SplittedPagesStatus.CUT_NOT_EXIST].includes(manuscriptProcResult) && 'Осуществить разрезку'}
              {manuscriptProcResult === SplittedPagesStatus.PROCESSING && 'Разрезается...'}
            </Button>
          </>
        )}
      </div>
      <ManuscriptView manuscript={manuscript} />
    </>
  );
};
