import { useState } from "react";
import { AllowedShape, Annotation, type ContentProps, type IAnnotation } from "react-mark-image";
import { SlavicInput } from "../morph-dictionary/SlavicField";
import { Button, Form } from "antd";
import { SaveFilled } from "@ant-design/icons";

const annotationContent = ({annotation}: ContentProps) => {
  const {x, y, height} = annotation.geometry;
  return (
    <div
      style={{
        padding: '7px',
        marginTop: '2px',
        borderRadius: '5px',
        backgroundColor: 'white',
        position: 'absolute',
        display: 'flex',
        fontFamily: 'MonomakhUnicode',
        left: `${x}%`,
        top: `${y + height}%`,
      }}
    >
      {annotation.data.text}
    </div>
  )
};

export const AnnotationTab = ({src, defaultAnnotations, onSave}:
  {src: string, defaultAnnotations: IAnnotation[], onSave: (annotations: IAnnotation[]) => void}) => {
  const [annotations, setAnnotations] = useState<IAnnotation[]>(defaultAnnotations);
  const handleSaveAnnotation = (annotation: IAnnotation) => {
    setAnnotations((oldAnnotations) => {
      const index = oldAnnotations.findIndex(({data}) => data.id === annotation.data.id);
      if (index === -1) {
        return [...oldAnnotations, annotation];
      } else {
        return [...oldAnnotations.slice(0, index), annotation, ...oldAnnotations.slice(index + 1)];
      }
    });
  };
  const annotationEditor = ({annotation}: ContentProps) => {
    const {x, y, width, height} = annotation.geometry;
    let left, right;
    if (x > 50) {
      right = `${100 - (x + width)}%`;
    } else {
      left = `${x}%`;
    }
    return (
    <Form
      style={{
        padding: '7px',
        borderRadius: '5px',
        backgroundColor: 'white',
        position: 'absolute',
        border: '1px solid #ccc',
        zIndex: 1,
        display: 'flex',
        left,
        right,
        top: `${y + height}%`,
      }}
      onFinish={(values) => {
        annotation.data.text = values.name;
        handleSaveAnnotation(annotation);
      }}
    >
      <Form.Item name='name' style={{margin: 0}}>
        <SlavicInput
          style={{
            display: 'flex',
            minWidth: 100,
            fontFamily: 'MonomakhUnicode'
          }}
        />
      </Form.Item>
      <Button
        type='primary'
        htmlType='submit'
        style={{
          display: 'flex',
          marginLeft: '5px',
        }}
        >
          Ввести аннотацию
      </Button>
    </Form>
    );
  };
  return (
  <>
    <Button
      type='primary'
      onClick={() => onSave(annotations)}
      title='Сохранить изменения'
    >
      <SaveFilled />
    </Button>
    <Annotation
      src={src}
      annotations={annotations}
      onAnnotationsUpdate={setAnnotations}
      allowedShapes={[
        AllowedShape['Rectangle']
      ]}
      overlayOptions={{
        displayOverlay: true,
        overlayText: 'Зажмите и двигайте, чтобы выделить область',
      }}
      renderContent={annotationContent}
      renderEditor={annotationEditor}
    />
  </>
  );
}
