import { API_ROOT } from '../constants';
import { ApiError } from '../layout/errors';
import { User, Token } from './types';

export const getToken = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<Token> => {
  const response = await fetch(`${API_ROOT}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });
  if (!response.ok) {
    throw new ApiError('login failed', response);
  }
  const { token } = await response.json();
  return token;
};

export const signUp = async (user: User & { password: string }) => {
  const response = await fetch(`${API_ROOT}/sign-up`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(user),
  });
  if (!response.ok) {
    throw new ApiError('sign up failed', response);
  }
};
