import CSS from 'csstype';

const defaultStyle: CSS.Properties = {
  padding: '20px 40px',
  margin: '0',
  background: '#fff',
  border: '1px solid #fff',
};

export const Panel = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: CSS.Properties;
}) => {
  return <div style={{ ...defaultStyle, ...style }}>{children}</div>;
};
