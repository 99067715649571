import type {Manuscript, Repository, WithPagesWithVyaz} from "../manuscripts/types";
import type {ColumnsType} from "antd/es/table";
import {Button, Tooltip} from "antd";
import {getFullCipher, getHistoricDate} from "../manuscripts/utils";
import {monthMap} from "../manuscripts/types";
import {useLocalStorage} from "../utils";
import React, {useMemo, useState} from "react";
import {VyazBase} from "./types";
import {SlavicInput} from "../morph-dictionary/SlavicField";
import { useNavigate } from "react-router-dom";
import { TableWithVyaz } from "./TableWithVyaz";


type DataType = {
  key: React.Key;
  pageNumber: number;
  manuscript: Manuscript;
  vyaz: VyazBase;
};

const getColumns = (d: DataType[], r:Repository[]): ColumnsType<DataType> => [
  {
    title: 'Вязь',
    render: (d: DataType) => (
      <div style={{fontFamily: 'MonomakhUnicode'}}>
        {d.vyaz.name}
      </div>
    ),
    key: 'name',
    sorter: (a, b) => a.vyaz.name.localeCompare(b.vyaz.name),
    defaultSortOrder: 'ascend',
  },

  {
    title: 'Хранилище',
    key: 'repository',
    render: (d: DataType) => (
      <Tooltip placement="bottomLeft" title={d.manuscript.repository.name}>
        {d.manuscript.repository.shortName}
      </Tooltip>
    ),
    filters: r.map((repository) => ({
      text: repository.shortName,
      value: repository.id,
    })),
    onFilter: (value, record: DataType) => record.manuscript.repository.id === value,
    sorter: (a, b) => a.manuscript.repository.name.localeCompare(b.manuscript.repository.name),
    defaultSortOrder: 'ascend',
  },

  {
    title: 'Фонд, собрание, коллекция',
    key: 'collection',
    render: (d: DataType) => d.manuscript.collection.name,
    filters: r.map((repository) => ({
      text: repository.shortName,
      value: `repository-${repository.id}`,
      children: repository.collections.map((collection) => ({
        text: collection.name,
        value: collection.id,
      })),
    })),
    filterMode: 'tree',
    filterSearch: true,
    onFilter: (value, record: DataType) =>
      record.manuscript.collection.id === value ||
      `repository-${record.manuscript.repository.id}` === value,
  },

  {
    title: 'Шифр',
    key: 'fullCipher',
    render: (_, a: DataType) => (
      <span style={{ cursor: 'pointer' }}>{getFullCipher(a.manuscript)}</span>
    ),
    filters: d.map((a) => {
      const cipher = getFullCipher(a.manuscript);
      return {
        text: cipher,
        value: cipher,
      };
    }),
    filterSearch: true,
    onFilter: (value, record: DataType) => getFullCipher(record.manuscript) === value,
  },

  {
    title: 'Месяцы',
    key: 'months',
    render: (_, a: DataType) =>
      a.manuscript.months.map((m) => monthMap.get(m)!).join(', '),
  },

  {
    title: 'Извод',
    key: 'redaction',
    render: (d: DataType) => d.manuscript.redaction.name,
    sorter: (a, b) => {return a.manuscript.redaction.name.toLowerCase().localeCompare(
        b.manuscript.redaction.name.toLowerCase()
    )},
  },

  {
    title: 'Датировка',
    key: 'historicDate',
    render: (_, a: DataType) => getHistoricDate(a.manuscript),
  },

  {
    title: 'Страница',
    key: 'page',
    render: (d: DataType) => (
      <Tooltip placement="bottomLeft" title={d.pageNumber + 1}>
        {d.pageNumber + 1}
      </Tooltip>
    ),
    sorter: (a, b) => {return a.pageNumber - b.pageNumber},
    defaultSortOrder: 'ascend',
  },
];

const usePageSize = () => useLocalStorage('VyazTable/pageSize', '10');

export const VyazTable = ({ manuscripts }: {
  manuscripts: WithPagesWithVyaz<Manuscript>[];
}) => {
  const [chosenPageSize, setChosenPageSize] = usePageSize();
  const navigate = useNavigate();
  const [items, repositories] = useMemo(() => {
    let items: DataType[] = [];
    let repositories: Repository[] = [];
    for (const manuscript of manuscripts) {
      items = items.concat(manuscript.pages.map(({id, pageNumber, vyaz}) => ({
        key: id,
        pageNumber,
        manuscript,
        vyaz,
      })));
      if (!repositories.includes(manuscript.repository)) {
        repositories.push(manuscript.repository);
      }
    }
    return [items, repositories];
  }, [manuscripts]);
  const columns = getColumns(items, repositories)
  const onShowSizeChange = (current: number, pageSize: number) => {
    setChosenPageSize(String(pageSize));
  };
  const [globalFilter, setGlobalFilter] = useState('');
  const filteredVyaz = items.filter(
    (a) => {
      return  a.vyaz.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
        a.manuscript.collection.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
        a.manuscript.repository.shortName.toLowerCase().includes(globalFilter.toLowerCase()) ||
        a.manuscript.repository.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
        a.manuscript.redaction.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
        getFullCipher(a.manuscript).toLowerCase().includes(globalFilter.toLowerCase()) ||
        a.manuscript.months.some((month) => monthMap.get(month)!.toLowerCase().includes(globalFilter.toLowerCase())) ||
        getHistoricDate(a.manuscript).toLowerCase().includes(globalFilter.toLowerCase())
    }
  );
  return (
    <div>
      <div style={{display: "flex", marginBottom: '5px'}}>
        <SlavicInput
          style={{
            display: 'flex',
            fontFamily: 'MonomakhUnicode'
          }}
          enableMode
          placeholder='Поиск'
          value={globalFilter}
          onChange={setGlobalFilter}
        />
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => setGlobalFilter('')}
        >
          Очистить
        </Button>
      </div>
      <div>
        <TableWithVyaz
          onClick={(record:DataType) => () => {
            navigate(
              `/manuscripts/${record.manuscript.id}/pages/${record.pageNumber + 1}`,
              {state:{tab: 'vyaz'}});
          }}
          pagination={{
            position: ['bottomRight', 'topRight'],
            pageSize: Number(chosenPageSize),
            onShowSizeChange,
          }}
          columns={columns}
          dataSource={filteredVyaz}
        />
      </div>
    </div>
  );
};
