import CSS from 'csstype';
import { CalendarOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { Link } from "react-router-dom";

const numToText: { [key: number]: string } = {
  0: "Развороты без разрезов",
  1: "Валидация разрезов",
  2: "Идёт разрезка",
  3: "Разрезана на страницы"
};

type ManuscriptPreviewProps = {
  thumbnail: string;
  title: string;
  historicDate: string;
  createdAt: Date;
  splittedPagesStatus: number;
  uuid: string;
};

const imgStyle: CSS.Properties = {
  height: '160px',
  maxWidth: '240px',
  width: 'auto',
  margin: 'auto'
};

const cardStyle: CSS.Properties = {
  width: '240px',
  height: '300px',
  margin: '5px 10px 15px 10px',
  display:'inline-block',
};

export const ManuscriptPreview = ({
  thumbnail,
  title,
  historicDate,
  createdAt,
  splittedPagesStatus,
  uuid,
}: ManuscriptPreviewProps) => {
  const description = (
    <>
      <div style={{ marginBottom: '2vm' }}>
        <CalendarOutlined />
        {historicDate}
      </div>
      <div>Добавлена {createdAt.toLocaleDateString('ru')}</div>
      <div>{numToText[splittedPagesStatus] ?? "Неизвествен"}</div>
    </>
  );
  const img = <img style={imgStyle} alt={title} src={thumbnail} />;

  return (
    <Link to={`/manuscripts/${uuid}`}>
      <Card
        hoverable
        style={cardStyle}
        cover={img}
      >
        <Card.Meta title={title} description={description} />
      </Card>
    </Link>
  );
};
