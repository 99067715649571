import type CSS from 'csstype';
import {Button, Divider, Form, Image, Input} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import * as api from './api';
import { PageTitle } from '../layout/PageTitle';
import { Panel } from '../layout/Panel';
import { passwordRepeatRule, passwordRules, emailRule, getNextUrl, buildLoginUrl, buildRegisterUrl } from './utils';
import { User } from './types';
import { useForm } from 'antd/es/form/Form';
import { ContentTitle } from '../layout/ContentTitle';
import loginLis from '../images/ЛИС-2.jpg'
import {WEBSITE_TITLE} from "../constants";

const requiredFieldRule = { required: true, message: 'Поле обязательно' };

const styleLogin: CSS.Properties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  height: '95vh',
  border: '1px',
  borderColor: '#333',
  borderRadius: '5px',
  position: 'relative',
};

const styleRegister: CSS.Properties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  height: '110vh',
  border: '1px',
  borderColor: '#333',
  borderRadius: '5px',
  position: 'relative',
};

const panelStyle: CSS.Properties = {
  minWidth: '270px',
  width: '30vw',
  maxWidth: '600px',
  boxSizing: 'border-box',
  borderRadius: '30px'
};

const header = (
  <ContentTitle style={{color: '#eee', textAlign: 'center'}}>
  <span style={{fontSize: '1.1em'}}>{WEBSITE_TITLE}</span>
  <br />
  <span style={{fontSize: '0/9em'}}>Лингвистическая интеллектуальная среда</span>
</ContentTitle>
);

export const LoginPage = () => {
  const navigate = useNavigate();
  const nextUrl = getNextUrl(window.location.href);
  const [form] = useForm<{ email: string; password: string }>();
  const login = async (value: { email: string; password: string }) => {
    let token: string;
    try {
      token = JSON.stringify(await api.getToken(value));
    } catch (err) {
      form.setFields([
        { name: 'email', errors: ['Неправильная почта или пароль'] },
      ]);
      return;
    }
    localStorage.setItem('authToken', token);
    navigate(nextUrl, { replace: true });
  };
  return (
    <PageTitle title="Вход">
      <Image src={loginLis} preview={false} style={{position: "fixed", top: 0, height: '100vh', zIndex:-1}} placeholder={true}/>
      <div style={styleLogin}>
        {header}
        <Panel style={panelStyle}>
          <ContentTitle>Вход</ContentTitle>
          <Form
            onFinish={login}
            layout="vertical"
            style={{ marginBottom: 30 }}
            autoComplete="off"
            requiredMark={false}
            form={form}
          >
            <Form.Item
              label="Электронная почта"
              name="email"
              rules={[emailRule, requiredFieldRule]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Пароль"
              name="password"
              rules={[requiredFieldRule]}
            >
              <Input.Password />
            </Form.Item>
            <Button block type="primary" htmlType="submit">
              Войти
            </Button>
          </Form>
          <span>Ещё нет аккаунта? </span>
          <Link style={{ textDecoration: 'none' }} to={buildRegisterUrl(nextUrl)}>
            Зарегистрироваться
          </Link>
        </Panel>
      </div>
    </PageTitle>
  );
};

const defaultOptionalFields = {
  firstName: '',
  middleName: '',
  surname: '',
  phone: '',
  organization: '',
  position: '',
} as const;

export const RegisterPage = () => {
  const navigate = useNavigate();
  const nextUrl = getNextUrl(window.location.href);
  const register = async (value: User & { password: string }) => {
    await api.signUp(value);
    navigate(buildLoginUrl(nextUrl));
  };
  return (
    <PageTitle title="Регистрация">
      <Image src={loginLis} preview={false} style={{position: "fixed", top: 0, height: '100vh'}} placeholder={true}/>
      <div style={styleRegister}>
        {header}
        <Panel style={panelStyle}>
        <ContentTitle>Регистрация</ContentTitle>
          <Divider style={{ margin: '0 0 15px' }} />
          <Form
            onFinish={register}
            layout="vertical"
            style={{ marginBottom: 30 }}
            initialValues={defaultOptionalFields}
            autoComplete="off"
          >
            <Form.Item
              label="Электронная почта"
              required
              name="email"
              rules={[emailRule, requiredFieldRule]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Пароль"
              required
              name="password"
              rules={passwordRules.concat(requiredFieldRule)}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Подтверждение пароля"
              required
              name="passwordRepeat"
              rules={[passwordRepeatRule('password'), requiredFieldRule]}
              dependencies={['password']}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Фамилия"
              required
              name="surname"
              rules={[requiredFieldRule]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Имя"
              required
              name="firstName"
              rules={[requiredFieldRule]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Отчество" name="middleName">
              <Input />
            </Form.Item>
            <Form.Item label="Телефон" name="phone">
              <Input />
            </Form.Item>
            <Form.Item label="Организация" name="organization">
              <Input />
            </Form.Item>
            <Form.Item label="Должность" name="position">
              <Input />
            </Form.Item>
            <Button block type="primary" htmlType="submit">
              Зарегистрироваться
            </Button>
          </Form>
          <div style={{ textAlign: 'center' }}>
            <span>Уже есть аккаунт? </span>
            <Link style={{ textDecoration: 'none' }} to={buildLoginUrl(nextUrl)}>
              Войдите в систему
            </Link>
          </div>
        </Panel>
      </div>
    </PageTitle>
  );
};

export const ProfilePage = () => {
  return (
    <PageTitle title="Профиль">
      <Panel>Мой профиль</Panel>
    </PageTitle>
  );
};
