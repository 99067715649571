import { message } from "antd";
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { LoadImagesForm } from "../LoadImagesForm";
import { getAuthToken } from "../../profile/utils";
import { ManuscriptContext } from "../types";
import * as api from '../api';

export const LoadImagesTab = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const {manuscript} = useContext(ManuscriptContext)!;
  const authToken = getAuthToken()!;
  const loadImages = async (files: File[]) => {
    try {
      await api.uploadFiles(authToken, manuscript.id, files);
    } catch (err) {
      messageApi.error('Ошибка при отправке данных на сервер');
      return;
    }
    navigate('.', { replace: true });
  };
  return (
    <>
      {contextHolder}
      <LoadImagesForm onUpload={loadImages} />
    </>
  );
};
