import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  SelectProps,
  Space,
} from 'antd';
import {
  ManuscriptBase,
  Redaction,
  Repository,
  centuryPartOptions,
  formatOptions,
  handwritingTypeOptions,
  materialOptions,
  monthOptions,
  musicalSignOptions,
} from './types';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useForm, useWatch } from 'antd/es/form/Form';

const requiredFieldRule = { required: true, message: 'Поле обязательно' };
const invalidUrlRule = {
  type: 'url',
  message: 'Невалидный формат ссылки',
} as const;

const historicDateFormatOptions = [
  { value: 'unknown', label: 'Не указано' },
  { value: 'year', label: 'Год' },
  { value: 'century', label: 'Век' },
  { value: 'centuryRange', label: 'Период' },
];

type HistoricDateMode = 'unknown' | 'year' | 'century' | 'centuryRange';

type FormResult = ManuscriptBase & {
  historicDateMode: HistoricDateMode;
};

const HistoricDateSubForm = () => {
  const form = useFormInstance<FormResult>();
  const mode = useWatch('historicDateMode', form);
  const yearField = (
    <Form.Item<FormResult> name="exactDateYear" rules={[requiredFieldRule]}>
      <InputNumber min={1} max={new Date().getFullYear()} controls={false} />
    </Form.Item>
  );
  const firstCenturyFields = (
    <Space.Compact>
      <Form.Item<FormResult>
        name="firstCenturyPrefix"
        rules={[requiredFieldRule]}
      >
        <Select
          options={centuryPartOptions}
          style={{ minWidth: 180, flex: 1 }}
        />
      </Form.Item>
      <Form.Item<FormResult> name="firstCentury" rules={[requiredFieldRule]}>
        <InputNumber min={1} max={21} controls={false} style={{ width: 50 }} />
      </Form.Item>
    </Space.Compact>
  );
  const lastCenturyFields = (
    <Space.Compact>
      <Form.Item<FormResult>
        name="lastCenturyPrefix"
        rules={[requiredFieldRule]}
      >
        <Select
          options={centuryPartOptions}
          style={{ minWidth: 180, flex: 1 }}
        />
      </Form.Item>
      <Form.Item<FormResult> name="lastCentury" rules={[requiredFieldRule]}>
        <InputNumber min={1} max={21} controls={false} style={{ width: 50 }} />
      </Form.Item>
    </Space.Compact>
  );

  return (
    <Space direction="vertical" style={{ display: 'flex', flex: 1 }}>
      <span>Датировка</span>
      <Form.Item name="historicDateMode">
        <Select
          options={historicDateFormatOptions}
          style={{ minWidth: 100, width: '100%' }}
          placement="topLeft"
        />
      </Form.Item>
      {mode === 'year' && yearField}
      {mode === 'century' && firstCenturyFields}
      {mode === 'centuryRange' && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            verticalAlign: 'center',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {firstCenturyFields}
          {lastCenturyFields}
        </div>
      )}
    </Space>
  );
};

type ManuscriptEditFormProps = {
  initialValue?: Partial<ManuscriptBase>;
  repositories: Repository[];
  redactions: Redaction[];
  onCancel: () => void;
  onSave: (value: Omit<ManuscriptBase, 'id'>) => void;
};

const getCollectionOptions = (
  repositories: Repository[]
): SelectProps['options'] => {
  return repositories.map(({ id: repositoryId, shortName, collections }) => ({
    key: `repository-${repositoryId}`,
    label: shortName,
    options: collections.map(({ id: collectionId, name }) => ({
      value: collectionId,
      label: name,
    })),
  }));
};

const resolveHistoricDateMode = (manuscript?: Partial<ManuscriptBase>) => {
  if (manuscript?.exactDateYear) {
    return 'year';
  } else if (manuscript?.lastCentury) {
    return 'centuryRange';
  } else if (manuscript?.firstCentury) {
    return 'century';
  } else {
    return 'unknown';
  }
};

export const ManuscriptEditForm = ({
  initialValue,
  repositories,
  redactions,
  onCancel,
  onSave,
}: ManuscriptEditFormProps) => {
  const collectionOptions = getCollectionOptions(repositories);
  const redactionOptions = redactions.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const buttons = (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10,
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'stretch',
      }}
    >
      <Button
        type="primary"
        htmlType="submit"
        style={{ backgroundColor: '#080' }}
      >
        Сохранить
      </Button>
      <Button type="primary" htmlType="button" onClick={onCancel}>
        Отмена
      </Button>
    </div>
  );
  const [form] = useForm<FormResult>();
  const handleFinish = ({ historicDateMode, ...result }: FormResult) => {
    if (historicDateMode !== 'century' && historicDateMode !== 'centuryRange') {
      result.firstCentury = null;
      result.firstCenturyPrefix = null;
    }
    if (historicDateMode !== 'centuryRange') {
      result.lastCentury = null;
      result.lastCenturyPrefix = null;
    }
    if (historicDateMode !== 'year') {
      result.exactDateYear = null;
    }
    onSave(result);
  };
  const defaultValues = {
    link: '',
    note: '',
    pagesStateDescription: '',
    specialName: '',
    handwritingBoundaries: '',
    months: [],
    historicDateMode: resolveHistoricDateMode(initialValue),
  };
  return (
    <Form
      form={form}
      onFinish={handleFinish}
      initialValues={{ ...defaultValues, ...initialValue }}
      layout="vertical"
      colon={false}
    >
      {buttons}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          gap: 10,
        }}
      >
        <Form.Item<FormResult> label="Месяцы" name="months" style={{ flex: 1 }}>
          <Select mode="multiple" options={monthOptions} />
        </Form.Item>
        <Form.Item<FormResult>
          label="Музыкальные знаки"
          name="musicalSign"
          style={{ minWidth: 200 }}
          rules={[requiredFieldRule]}
        >
          <Select options={musicalSignOptions} />
        </Form.Item>
        <HistoricDateSubForm />
      </div>
      <Form.Item<FormResult> label="Специальное название" name="specialName">
        <Input />
      </Form.Item>
      <Divider />
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <Form.Item<FormResult>
          label="Шифр"
          name="cipher"
          style={{ maxWidth: 150 }}
          rules={[requiredFieldRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item<FormResult>
          label="Фонд, собрание, коллекция"
          name="collectionId"
          style={{ flex: 1, minWidth: 300 }}
          rules={[requiredFieldRule]}
        >
          <Select options={collectionOptions} />
        </Form.Item>
      </div>
      <Divider />
      <Space wrap>
        <Form.Item<FormResult>
          label="Извод"
          name="redactionId"
          style={{ minWidth: 150 }}
          rules={[requiredFieldRule]}
        >
          <Select options={redactionOptions} />
        </Form.Item>
        <Form.Item<FormResult>
          label="Формат"
          name="format"
          style={{ minWidth: 110 }}
          rules={[requiredFieldRule]}
        >
          <Select options={formatOptions} />
        </Form.Item>
        <Form.Item
          label="Количество листов"
          name="pagesCount"
          rules={[requiredFieldRule]}
        >
          <InputNumber min={1} style={{ minWidth: 160 }} controls={false} />
        </Form.Item>
      </Space>
      <Form.Item<FormResult>
        label="Сохранность текста"
        name="pagesStateDescription"
      >
        <Input />
      </Form.Item>
      <Divider />
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <Form.Item<FormResult>
          label="Материал"
          name="material"
          style={{ minWidth: 150 }}
          rules={[requiredFieldRule]}
        >
          <Select options={materialOptions} />
        </Form.Item>
        <Form.Item<FormResult>
          label="Тип почерка"
          name="handwritingType"
          style={{ minWidth: 150 }}
          rules={[requiredFieldRule]}
        >
          <Select options={handwritingTypeOptions} />
        </Form.Item>
        <Form.Item label="Количество почерков" name="numberOfHandwritings">
          <InputNumber min={1} style={{ minWidth: 160 }} controls={false} />
        </Form.Item>
      </div>
      <Form.Item<FormResult>
        label="Границы почерков"
        name="handwritingRegionsDescription"
      >
        <Input />
      </Form.Item>
      <Divider />
      <Form.Item<FormResult>
        label="Описание на сайте хранилища (ссылка)"
        name="link"
        rules={[invalidUrlRule]}
      >
        <Input />
      </Form.Item>
      <Form.Item<FormResult> label="Примечание" name="note">
        <Input />
      </Form.Item>
      {buttons}
    </Form>
  );
};
