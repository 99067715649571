import { useNavigate } from 'react-router-dom';
import type CSS from 'csstype';

const resetStyle: CSS.Properties = { all: 'unset', outline: 'revert' };

const clickHandlerStyle: CSS.Properties = {
  position: 'absolute',
  inset: '0',
  backgroundColor: 'transparent',
};

export const LogoutButton = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };
  return (
    <button type="button" style={resetStyle}>
      <span style={clickHandlerStyle} onClick={logout} />
      {children}
    </button>
  );
};
