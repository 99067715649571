import CSS from 'csstype';
import { useMenuLinks, RoutedMenu } from '../routes/menu';
import { LogoutButton } from '../profile/LogoutButton';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import Icon, { LogoutOutlined } from '@ant-design/icons';
import { ReactComponent as MephiSvg } from '../images/mephi.svg';
import {Link} from "react-router-dom";

// Пункт меню для выхода пользователя из системы
const logout: MenuItemType = {
  icon: <LogoutOutlined />,
  key: 'logout',
  label: <LogoutButton>Выйти</LogoutButton>,
  danger: true,
};

const logoContainerStyle: CSS.Properties = {
  paddingLeft: '10px',
  borderBottom: '1px solid #eee',
  borderRight: '1px solid #eee',
};

export const SideBar = ({collapsed}: {collapsed: boolean}) => {
  const menuLinks = useMenuLinks();
  const items = menuLinks.concat([logout]);
  return (
    <div>
      <div style={logoContainerStyle}>
        <Link style={{ display: 'flex', height: '60px', alignItems: 'center', color: 'black', whiteSpace: 'nowrap' }} to={'/'}>
          <Icon style={{ fontSize: 50 }} component={MephiSvg} />
          {!collapsed && <span style={{margin: '0 10px'}}>НИЯУ МИФИ</span>}
        </Link>
      </div>
      <RoutedMenu items={items} />
    </div>
  );
};
