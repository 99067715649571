import React, { useReducer } from "react";

// zoom - множитель, в который увеличивается изображение.
// left, top - относительные смещения левого верхнего угла изображения.
// Так как используется правило transform: "translate(-50%, -50%)",
// то происходит дополнительное смещение,
// по которому центр переходит на место левого верхнего угла.
export type ImageView = { zoom: number; left: number; top: number };
// Перейти в исходное состояние
type Reset = { type: "reset" };
// Увеличить картинку в заданное число раз относительно точки
type Zoom = {
  type: "zoom";
  factor: number;
  pivot: { x: number; y: number };
  baseSize: { width: number; height: number };
};
// Переместить картинку с сохранением масштаба,
// чтобы одна точка перешла в другую
type Translate = {
  type: "translate";
  fromPoint: { x: number; y: number };
  toPoint: { x: number; y: number };
  baseSize: { width: number; height: number };
};
// Максимально увеличить картинку, чтобы она была в заданных границах
type Region = {
  type: "region";
  region: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  baseSize: { width: number; height: number };
}
// задать значения трансформации картинки
type SetView = { type: "set" } & ImageView;

const movePoint = (prevState: ImageView, action: Omit<Translate, 'type'> & {factor: number}) => {
  const { fromPoint, toPoint, factor } = action;
  const { width, height } = action.baseSize;
  const zoom = prevState.zoom * factor;
  if (zoom > 100 || zoom < 0.1) {
    return prevState;
  }
  const prevLeftPx = prevState.left * width;
  const prevTopPx = prevState.top * height;
  const leftPx = (prevLeftPx - fromPoint.x) * factor + toPoint.x;
  const topPx = (prevTopPx - fromPoint.y) * factor + toPoint.y;
  const left = leftPx / width;
  const top = topPx / height;
  return { zoom, left, top };
}

const changeView = (
  prevState: ImageView,
  action: Reset | Zoom | Translate | Region | SetView,
): ImageView => {
  switch (action.type) {
    case "reset":
      return { zoom: 1, left: 0.5, top: 0.5 };
    case "zoom": {
      const { factor, pivot, baseSize } = action;
      return movePoint(prevState, {fromPoint: pivot, toPoint: pivot, factor, baseSize});
    }
    case "translate": {
      const { fromPoint, toPoint, baseSize } = action;
      return movePoint(prevState, {fromPoint, toPoint, factor: 1, baseSize});
    }
    case "region": {
      const {region, baseSize} = action;
      const factor = Math.min(baseSize.height / region.height, baseSize.width / region.width);
      const fromPoint = {x: region.left + region.width / 2, y: region.top + region.height / 2};
      const toPoint = {x: baseSize.width / 2, y: baseSize.height / 2};
      return movePoint(prevState, {fromPoint, toPoint, factor, baseSize});
    }
    case "set": {
      if (action.zoom > 100 || action.zoom < 0.1) {
        return prevState;
      }
      return action;
    }
    default:
      return prevState;
  }
};

export const useImageView = () => {
  return useReducer(changeView, {
    zoom: 1,
    left: 0.5,
    top: 0.5,
  });
}

type ZoomedImageProps = {
  view: ImageView,
  src: string,
};

export const ZoomedImage = React.forwardRef<HTMLImageElement, ZoomedImageProps>(({view, src}, ref) => {
  return (
    <div style={{position: 'relative', overflow: 'hidden', background: '#ccc'}}>
      {/* этот тег невидимый, задаёт размеры для контейнера */}
      <img style={{width: '100%', opacity: 0}} src={src} alt="" />
      <img
        ref={ref}
        style={{
          height: `${100 * view.zoom}%`,
          position: "absolute",
          top: `${100 * view.top}%`,
          left: `${100 * view.left}%`,
          transform: "translate(-50%, -50%)",
        }}
        draggable={false}
        src={src}
        alt=""
      />
    </div>
  );
});
