import CSS from 'csstype';

const defaultStyle: CSS.Properties = {
  fontWeight: 'bold',
  color: '#666',
  fontSize: '1.2em',
  fontFamily: 'sans-serif',
  margin: 0,
};

export const ContentTitle = ({
  style,
  ...props
}: Omit<React.HTMLProps<HTMLElement>, 'ref'>) => {
  return <h1 style={{ ...defaultStyle, ...style }} {...props} />;
};
