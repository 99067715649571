import { ConfigProvider, Table, type TableProps } from 'antd';
import { MouseEventHandler } from 'react';

type TableWithVyazProps<RecordType> = {
  onClick: (record: RecordType, index: keyof RecordType) => MouseEventHandler<HTMLSpanElement> | null;
} & TableProps<RecordType>;

export function TableWithVyaz<RecordType extends Record<PropertyKey, any>>({
    onClick,
    columns,
    ...props
  }: TableWithVyazProps<RecordType>) {
    columns = columns?.map((column) => ({
      ...column,
      render: (value, record, index) => {
        const event = onClick(record, index);
        const children = column.render?.(value, record, index) ?? value;
        if (event == null) {
          return <span>children</span>;
        }
        return (
          <span
            style={{ padding: 16, display: 'block', color: 'inheritэ', cursor:'pointer'}}
            onClick={event}
          >
            {children}
          </span>
        );
      },
    }));
    return (
      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellPaddingBlock: 0,
              cellPaddingInline: 0,
              cellPaddingBlockMD: 0,
              cellPaddingInlineMD: 0,
              cellPaddingBlockSM: 0,
              cellPaddingInlineSM: 0,
            },
          },
        }}
      >
        <Table<RecordType> columns={columns} {...props} />
      </ConfigProvider>
    );
  }
